import React from "react"
import { Link } from "react-router-dom";
import { ViewGridIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

const GroupTile = ({ title, group, devices = [] }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/group/${group.id}`} className="flex flex-col mb-2 p-4 rounded bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow cursor-pointer" style={{ minWidth: 200, maxWidth: 200 }}>
      <div className="flex flex-row flex-grow">
        <ViewGridIcon className="w-4 h-4 mt-0.5 mr-2 text-gray-500 dark:text-gray-500" />
        <div className="flex flex-col flex-grow">
          <span className="text-sm text-gray-500 dark:text-gray-300 font-semibold">
            { title }            
          </span>
          <span className="text-xs font-semibold text-gray-400 mt-1">
            {
              (devices.length > 0) ? `${devices.length} ${(devices.length > 1)?t('components.group_tile.devices'):t('components.group_tile.device')}` : t('components.group_tile.empty')
            }
          </span>
        </div>
      </div>
    </Link>
  )
}

export default GroupTile