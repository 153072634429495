import { createSlice } from "@reduxjs/toolkit";

export const historyBarSlice = createSlice({
	name: "historybar",
	initialState: {
		from: null,
    to: null,
	},
	reducers: {
    setFrom(state, action) {
      state.from = action.payload;
    },
    setTo(state, action) {
      state.to = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setFrom, setTo } = historyBarSlice.actions;

export default historyBarSlice.reducer;