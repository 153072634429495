import React from "react";
import { AdjustmentsIcon, ChevronRightIcon, GlobeIcon, MapIcon, MoonIcon, UsersIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setStyle } from "../../redux/theme";

const SettingsTab = ({ icon = null, name, href = "/settings" }) => {
  return (
    <Link to={href} className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400 hover:text-gray-700 hover:dark:text-gray-300 px-3 py-3.5 rounded">
      <div className="flex flex-row items-center gap-1">
        { icon }
        <h3 className="font-[600] tracking-wide">
          { name }
        </h3>
      </div>
      <ChevronRightIcon className="w-6 h-6" />
    </Link>
  )
};

const DarkModeToggler = ({ icon = null, name }) => {
  const dispatch = useDispatch();
  const mode = useSelector(state => state.theme.style);

  return (
    <div className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-500 dark:text-gray-400 px-3 py-3.5 rounded">
      <div className="flex flex-row items-center gap-1">
        { icon }
        <h3 className="font-[600] tracking-wide">
          { name }
        </h3>
      </div>
      <label for="default-toggle" class="inline-flex relative items-center cursor-pointer">
        <input type="checkbox" defaultChecked={mode === "dark"} value="" id="default-toggle" class="sr-only peer" onChange={(event) => {
          if (!event.target.checked) {
            dispatch(setStyle("light"));
          } else {
            dispatch(setStyle("dark"));
          }
        }} />
        <div class="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      </label>
    </div>
  )
}

const SettingsPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col flex-grow px-4 my-3">
      {/* Create Filled Device heading with TrendingUpIcon */}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {t('pages.settings.title')}
        </h1>
      </div>
      <div className="grid grid-flow-row grid-col-1 gap-4 my-4">
        {/* <SettingsTab icon={<AdjustmentsIcon className="w-5 h-5 mr-2" />} name={"Device Presets"} /> */}
        <SettingsTab icon={<MapIcon className="w-5 h-5 mr-2" />} name={t('pages.settings.georeferencing.title')} href="/settings/georeferencing" />
        <SettingsTab icon={<GlobeIcon className="w-5 h-5 mr-2" />} name={`${ t('pages.settings.language.title') }  ( ${ String(i18n.language).toUpperCase() } )`} href="/settings/language" />

        {/* Dark Mode Toggle */}
        <DarkModeToggler icon={<MoonIcon className="w-5 h-5 mr-2" />} name={t('pages.settings.dark_mode.title')}/>
      </div>
    </div>
  );
};

export default SettingsPage;
