import React, { useEffect } from "react"
import { CubeTransparentIcon } from "@heroicons/react/outline";
import { CogIcon } from "@heroicons/react/solid";
import { Link, useParams } from "react-router-dom"
import { useFindAllDevicesQuery, useGetGroupQuery } from '../redux/services/api'

import GroupHeaderLoader from "../components/loaders/GroupHeaderLoader";
import DeviceList from "../components/DeviceList";
import DeviceTileLoader from "../components/loaders/DeviceTileLoader";

import { setDevices, setFocus } from "../redux/map";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const GroupPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const URLParams = useParams();

  // Fetch Group
  const {
    data: group,
    isLoading: loadingGroup,
  } = useGetGroupQuery(URLParams.groupID);

  // Fetch all devices and sort into group
  const {
    data,
    isLoading: loadingDevices,
    isSuccess: isSuccessDevices
  } = useFindAllDevicesQuery();

  let devices = [];
  if (isSuccessDevices) {
    devices = data.filter((device) => device.groups.includes(URLParams.groupID)) || [];
  }

  useEffect(() => {
    if (!devices) return;
    const serializedDevices = devices.map(d => d.id);
    dispatch(setDevices(serializedDevices));
    dispatch(setFocus(serializedDevices));
  }, [devices]);

  return (
    <div className="flex flex-col flex-grow px-4 mb-4">
      {/* Create group header */}
      <div className="flex flex-col mb-5">
        <div className="flex flex-row justify-between items-center">
          <span className="text-lg text-gray-700 dark:text-gray-300 font-semibold">
            {/* Show Group header loader while group loads */}
            { loadingGroup ? <GroupHeaderLoader className="flex flex-row" /> : group.name }
          </span>
          {/* Settings icon button */}
          {
            loadingGroup ? null : (
              <Link
                to="settings"
                className="flex flex-row items-center justify-center w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-800 text-gray-500 dark:text-gray-200 hover:bg-gray-300 hover:text-gray-600 focus:outline-none focus:shadow-outline transition-colors duration-150"
              >
                <CogIcon className="w-4 h-4" />
              </Link>
            )
          }
        </div>
      </div>
      {/* Show device content loader while group loads */}
      { loadingGroup ?
          <div className="flex flex-col gap-4">
            <DeviceTileLoader className="flex flex-row" />
            <DeviceTileLoader className="flex flex-row" />
            <DeviceTileLoader className="flex flex-row" />
            <DeviceTileLoader className="flex flex-row" />
            <DeviceTileLoader className="flex flex-row" />
          </div>
        :
          null
      }
      {/* Create device list if devices available */}
      { (!loadingGroup && !loadingDevices) && devices.length > 0 ?
        <div className="flex flex-col flex-grow">
          {(!loadingGroup && !loadingDevices) && <DeviceList groupID={URLParams.groupID} items={devices} itemsPerPage={15} />}
        </div>
        :
        null
      }
      {/* If no devices, show empty icon */}
      {(!loadingGroup && !loadingDevices) && devices.length === 0 && (
        <div className="flex flex-col flex-grow justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
            <p className="mt-3 text-gray-600 text-center">
              {t('pages.group.empty')}
            </p>
          </div>  
        </div>
      )}
    </div>
  )
};

export default GroupPage;