import { useDragLayer } from 'react-dnd';
import BinSVG from './BinSVG';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const DeviceDragger = ({ device }) => {
    return (
        <div className="relative rounded-full bg-green shadow-md flex flex-col justify-center items-center pointer-events-none" style={{ width: 48, height: 48 }}>
            <div className='absolute -top-7 left-0 right-0 w-28 text-sm text-center text-black dark:text-white drop-shadow -ml-7'>
                {device.friendly_name}
            </div>
            <BinSVG fill={device.statistics.device.fill_level} threshold={device.settings.fill.threshold} width="32px" height="32px" />
        </div>
    );
};

function getItemStyles(itemType, initialOffset, currentOffset, clientOffset) {
    if (!initialOffset || !currentOffset || !clientOffset) {
        return {
            display: 'none',
        };
    }
    let x, y = 0;
    if (itemType === 'device') {
        x = clientOffset.x - 24;
        y = clientOffset.y - 24;
    } else {
        x = clientOffset.x;
        y = clientOffset.y;
    }
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

export const DragLayer = (props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset, clientOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        clientOffset:  monitor.getClientOffset(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    function renderItem() {
        switch (itemType) {
            case 'device':
                return <DeviceDragger device={item} />;
            default:
                return null;
        }
    }
    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles} className='pointer-events-none'>
			<div style={getItemStyles(itemType, initialOffset, currentOffset, clientOffset)} className='pointer-events-none'>
				{renderItem()}
			</div>
		</div>
    );
};
