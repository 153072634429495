import React from "react"
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook';

import AuthLayout from "./layouts/Auth"
import DashboardLayout from "./layouts/Dashboard"
import DevicePage from "./pages/Device"
import DeviceSettingsPage from "./pages/DeviceSettings"
import EmployeePage from "./pages/Employee"
import EmployeesPage from "./pages/Employees"
import ExceptionsPage from "./pages/Stats/Exceptions"
import FilledDevicesPage from "./pages/Stats/FilledDevices"
import GeoreferencePage from "./pages/Settings/Georeferencing/Georeference"
import GeoreferencingPage from "./pages/Settings/Georeferencing"
import GroupPage from "./pages/Group"
import GroupSettingsPage from "./pages/GroupSettings"
import HomePage from "./pages/Home"
import LanguagePage from './pages/Settings/Language'
import LoginPage from "./pages/Login"
import LowBatteryDevicesPage from "./pages/Stats/LowBattery"
import NotificationsPage from "./pages/Notifications"
import Page404 from "./pages/404"
import Search from './components/Search';
import SettingsPage from "./pages/Settings"
import { GuestAuth, RequireAuth } from './middlewares/auth'
import { setSearchOpen } from "./redux/search";

const Router = () => {
  const dispatch = useDispatch();
  const searchOpen = useSelector(state => state.search.open);
  useHotkeys('cmd+k, alt+k', () => dispatch(setSearchOpen(true)));

  return (
    <BrowserRouter>
      {
        searchOpen ?
          <Search />
        :
          null
      }
      <Routes>
        <Route exact path="/" element={
          <RequireAuth>
            <DashboardLayout/>
          </RequireAuth>
        }>
          <Route index element={<HomePage/>} />
          <Route path="/employee">
            <Route index element={<EmployeesPage />} />
            <Route path=":employeeID" element={<EmployeePage />} />
          </Route>
          <Route path="/settings">
            <Route index element={<SettingsPage/>} />
            <Route path="/settings/language" element={<LanguagePage/>} />
            <Route path="/settings/georeferencing">
              <Route index element={<GeoreferencingPage />} />
              <Route path="/settings/georeferencing/:georeferenceID" element={<GeoreferencePage/>} />
            </Route>
          </Route>
          <Route path="/notifications" element={<NotificationsPage/>} />
          <Route path="/device/:deviceID">
            <Route index element={<DevicePage/>} />
            <Route path="/device/:deviceID/settings" element={<DeviceSettingsPage/>} />
          </Route>
          {/* Add Group Route */}
          <Route path="/group/:groupID">
            <Route index element={<GroupPage/>} />
            <Route path="/group/:groupID/settings" element={<GroupSettingsPage/>} />
            {/* Add Device Page */}
            <Route path="/group/:groupID/device/:deviceID">
              <Route index element={<DevicePage/>} />
              <Route path="/group/:groupID/device/:deviceID/settings" element={<DeviceSettingsPage/>} />
            </Route>
          </Route>
          <Route path="/stats">
            {/* Filled Devices Route */}
            <Route path="/stats/filled" index element={<FilledDevicesPage/>} />
            {/* Low Battery Devices Route */}
            <Route path="/stats/low-battery" index element={<LowBatteryDevicesPage/>} />
            {/* Exceptions Route */}
            <Route path="/stats/exceptions" index element={<ExceptionsPage/>} />
          </Route>
        </Route>
        <Route
          path="/login"
          element={
            <GuestAuth>
              <AuthLayout>
                <LoginPage/>
              </AuthLayout>
            </GuestAuth>
          }
        />
        <Route exact path="*" element={
          <AuthLayout>
            <Page404/>
          </AuthLayout>
        } />
      </Routes>
    </BrowserRouter>
  )
}

export default Router