import React from "react"
import { Field, ErrorMessage } from 'formik';

const InputWithLabel = ({ title, errors = null, ...props }) => {
  return (
    <React.Fragment>
      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-gray-500">
        { title }
      </label>
      <Field
        {...props}
        className={`mt-1 px-4 py-2 focus:ring-green-500 ${(errors?.password)?'border-red-500':'border-gray-200 dark:border-gray-900 focus:border-gray-400'} dark:bg-gray-900 disabled:opacity-60 dark:text-gray-300 block w-full sm:text-sm border-2  focus:outline-none rounded-md`}
      />
      <ErrorMessage
        component="div"
        name={props.name}
        className="text-xs text-red-500"
      />
    </React.Fragment>
  )
}

export default InputWithLabel