import React, { useEffect, useMemo } from "react"
import { CogIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";

import BinLoader from "../components/loaders/BinLoader"
import DeviceGraph from "../components/DeviceGraph";
import DeviceImage from "../components/DeviceImage";
import DeviceStatLoader from "../components/loaders/DeviceStatLoader"
import DeviceStatTile from "../components/DeviceStatTile";
import { setAverageFillLevel, setCompressionFactor, setCompressions, setLoading, setShow, setTimesEmptied } from "../redux/overviewbar";
import { setDevices, setFocus } from "../redux/map";
import { useGetDeviceHistoryQuery, useGetDeviceQuery } from '../redux/services/api'

const DevicePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const URLParams = useParams();
  const HistoryBarFrom = useSelector(state => state.historybar.from);
  const HistoryBarTo = useSelector(state => state.historybar.to);

  const {
    data: device,
    isLoading: loadingDevice,
    isSuccess: isSuccessDevice
  } = useGetDeviceQuery(URLParams.deviceID);

  const {
    data: deviceHistory,
    isLoading: loadingDeviceHistory,
    isFetching: fetchingDeviceHistory,
    isSuccess: isSuccessDeviceHistory
  } = useGetDeviceHistoryQuery({ id: URLParams.deviceID, start: HistoryBarFrom, end: HistoryBarTo });

  const FillColor = useMemo(() => {
    if (!device) return "";

    if (device.product_category === 'mose') {
      if (device.statistics.device.fill_level >= device.settings.fill.threshold) {
        if (device.notifications?.fill?.threshold) {
          if (device.statistics.device.fill_level > device.notifications.fill.threshold) {
            return "danger-progress"
          } else {
            return "warning-progress"
          }
        } else {
          return "danger-progress"
        }
      }
    } else if (device.product_category === 'tek') {
      if (device.notifications?.fill?.threshold) {
        if (device.statistics.device.fill_level > device.notifications.fill.threshold) {
          return "danger-progress"
        }
      }
    }
		return ""
	}, [device]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setShow(true));
    return () => {
      // Clear default values
      dispatch(setCompressions(0));
      dispatch(setCompressionFactor(0));
      dispatch(setAverageFillLevel(0));
      dispatch(setTimesEmptied(0));
      dispatch(setShow(false));
      dispatch(setLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set Map Focus on Init
  useEffect(() => {
    if (!isSuccessDevice || !device) return;
    dispatch(setDevices([device.id]));
    dispatch(setFocus([device.id]));
  }, [device, isSuccessDevice]);

  // Set Overview bar to loading if stats are loading or fetching
  React.useEffect(() => {
    if (loadingDeviceHistory || fetchingDeviceHistory) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loadingDeviceHistory, fetchingDeviceHistory ]);

  // Set Overview bar stats when stats are fetched or changed
  React.useEffect(() => {
    if (isSuccessDeviceHistory) {
      if (device?.product_category === 'mose') {
        dispatch(setCompressions(deviceHistory.overview.compressions));
        dispatch(setCompressionFactor(deviceHistory.overview.compression_factor));
      } else {
        dispatch(setCompressions(null));
        dispatch(setCompressionFactor(null));
      }
      dispatch(setAverageFillLevel(deviceHistory.overview.fill.avg.toFixed(0)));
      dispatch(setTimesEmptied(deviceHistory.overview.times_emptied));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccessDeviceHistory, deviceHistory, device ]);

  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-10 mt-5 relative">
        {
          (!loadingDevice) ?
            <Link to="settings" className="absolute top-0 right-5 flex flex-row items-center justify-center w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-800 text-gray-400 dark:text-gray-200 hover:bg-gray-400 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150">
              <CogIcon className="w-5 h-5" />
            </Link>
          :
            null
        }
        {
          (loadingDevice) ?
            <React.Fragment>
              <div className="relative px-5">
                <BinLoader className="flex flex-row h-40 flex-grow" />
              </div>
              <div className="flex flex-col flex-grow justify-center items-center">
                <DeviceStatLoader className="flex flex-row h-10 w-20 flex-grow"/>
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              <div className="relative px-5">
                <DeviceImage model={device?.product_model} />
                <div className={`progress-container ${FillColor}`}>
                  <progress className="w-20 h-2 text-gray-200 dark:text-gray-800" value={device.statistics.device.fill_level} max="100"> {device.statistics.device.fill_level}% </progress>
                </div>
              </div>
              <div className="flex flex-col flex-grow justify-center items-center">
                <h2 className="mt-4 text-gray-700 dark:text-gray-300 text-2xl font-semibold">
                  { device.friendly_name }
                </h2>
                <div className="mt-3 text-gray-400 dark:text-gray-600 text-sm font-semibold">
                  {t('pages.device.last_report')} {new Date(device.last_reported_at).getDate()}/{new Date(device.last_reported_at).getMonth()+1}/{new Date(device.last_reported_at).getFullYear()} - { new Date(device.last_reported_at).toLocaleTimeString() }
                </div>
              </div>
            </React.Fragment>
        }
      </div>
      {
        (loadingDevice) ?
          <div className="grid grid-cols-2 gap-4 mb-4 mx-4">
            <div>
              <DeviceStatLoader className="flex flex-row h-20 flex-grow"/>
            </div>
            <div>
              <DeviceStatLoader className="flex flex-row h-20 flex-grow"/>
            </div>
            <div>
              <DeviceStatLoader className="flex flex-row h-20 flex-grow"/>
            </div>
            <div>
              <DeviceStatLoader className="flex flex-row h-20 flex-grow"/>
            </div>
          </div>
        :
          <>
            <div className="flex flex-row flex-grow text-xs text-gray-500 dark:text-gray-600 font-semibold mx-4 mb-3">
              {t('pages.device.latest_stats_title')}
            </div>
            <div className="grid sm:grid-col-1 md:grid-cols-2 gap-4 mb-6 mx-4">
              <DeviceStatTile title={t('pages.device.stats.fill_level_title')} value={`${(device.statistics.device.fill_level < 25)?`${t('pages.device.less_than')} 25`:device.statistics.device.fill_level} %`}/>
              <DeviceStatTile title={t('pages.device.stats.battery_title')} value={`${device.statistics.battery.percentage.toFixed(0)} %`}/>
              {
                (device.statistics.temperature.available) ?
                  <DeviceStatTile title={t('pages.device.stats.temperature_title')} value={`${device.statistics.temperature.value.toFixed(1)} °C`}/>
                :
                  null
              }
              {
                (device.statistics.humidity.available) ?
                  <DeviceStatTile title={t('pages.device.stats.humidity_title')} value={`${device.statistics.humidity.value.toFixed(1)} %`}/>
                :
                  null
              }
              {
                (device.statistics.weight.available) ? 
                  <DeviceStatTile title={t('pages.device.stats.weight_title')} value={`${device.statistics.weight.value} kg`} className="col-span-2"/>
                :
                  null
              }
            </div>
          </>
      }
      {
        (isSuccessDevice) ?
          <>
            <div className="flex flex-row flex-grow text-xs text-gray-500 dark:text-gray-600 font-semibold mx-4 mb-3">
              {t('pages.device.stats_history_title')}
            </div>
            <div className="flex flex-col mx-4 mb-5">
              <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.fill_level_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"fill"} label={"%"} min={0} max={100} />
            </div>
            {
              device?.product_category === "mose" ?
                <div className="flex flex-col mx-4 mb-5">
                  <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.compressions_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"compressions"} label={"Compressions"} min={0} max={5} />
                </div>
              :
                null
            }
            {
              // If device has temperature sensor available show temperature graph
              (device.statistics.temperature.available) ?
                <div className="flex flex-col mx-4 mb-5">
                  <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.temperature_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"temperature"} label={"°C"} min={0} max={50} />
                </div>
              :
                null
            }
            {
              // If device has humidity sensor available then show humidity graph
              (device.statistics.humidity.available) ?
                <div className="flex flex-col mx-4 mb-5">
                  <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.humidity_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"humidity"} label={"%"} min={0} max={50} />
                </div>
              :
                null
            }
            {
              // If device has weight sensor available then show weight graph
              (device.statistics.weight.available) ?
                <div className="flex flex-col mx-4 mb-5">
                  <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.weight_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"weight"} label={"kg"} min={0} max={20} />
                </div>
              :
                null
            }
            <div className="flex flex-col mx-4 mb-5">
              <DeviceGraph loading={loadingDeviceHistory || fetchingDeviceHistory} title={t('pages.device.history.battery_title')} data={(deviceHistory)?deviceHistory.history:[]} iteratee={"battery"} label={"%"} min={0} max={100} />
            </div>
          </>
        :
          null
      }
    </React.Fragment>
  )
}

export default DevicePage
