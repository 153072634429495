import React from "react"
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-5xl dark:text-gray-300 font-semibold">404</h1>
      <h2 className="mt-2 text-lg dark:text-gray-500">
        Page not found
      </h2>
      {/* Create button tailwind */}
      <Link to="/" className="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full">
        Go to Dashboard
      </Link>
    </div>
  );
};

export default Page404;
