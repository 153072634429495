import React, { useEffect } from "react";
import { CubeTransparentIcon } from "@heroicons/react/outline";
import { useFindAllDevicesQuery } from "../../redux/services/api";

import DeviceList from "../../components/DeviceList";

import { setDevices, setFocus } from "../../redux/map";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


const LowBatteryDevicesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Get all devices
  const {
    data: devices,
    isSuccess: devicesSuccess
  } = useFindAllDevicesQuery();

  // Get Low Battery Devices
  const lowBatteryDevices = (devicesSuccess)?devices.filter(device => device.statistics.battery.percentage <= 15):[];

  useEffect(() => {
    if (!lowBatteryDevices) return;
    const serializedDevices = lowBatteryDevices.map((d) => d.id);
    dispatch(setDevices(serializedDevices));
    dispatch(setFocus(serializedDevices));
  }, [lowBatteryDevices]);

  return (
    <div className="flex flex-col flex-grow px-4 my-3">
      {/* Create Filled Device heading with TrendingUpIcon */}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {t('pages.stats.lowbattery.title')}
        </h1>
        <div className="flex flex-row items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-7 h-7 mr-2 text-blue-500" fill="currentColor">
            <g data-name="Layer 2">
              <g data-name="battery">
                <rect width="24" height="24" opacity="0" />
                <path
                  d="M15.83 6H4.17A2.31 2.31 0 0 0 2 8.43v7.14A2.31 2.31 0 0 0 4.17 18h11.66A2.31 2.31 0 0 0 18 15.57V8.43A2.31 2.31 0 0 0 15.83 6z" />
                <path d="M21 9a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1z" />
              </g>
            </g>
          </svg>
          <span className="text-lg text-gray-500 font-semibold ml-2">
            {lowBatteryDevices.length}
          </span>
        </div>
      </div>
      <p className="mt-2 text-gray-400 dark:text-gray-500">
        {t('pages.stats.lowbattery.description')}
      </p>
      {/* Show Filled Devices List */}
      <div className="flex flex-col my-6">
        <DeviceList absolute={true} items={lowBatteryDevices} itemsPerPage={15} />
      </div>
      {/* Show empty icon if there are no devices */}
      {lowBatteryDevices.length === 0 && (
        <div className="flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col justify-center items-center md:-mt-8">
            <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
            <p className="mt-3 text-gray-600 text-center">
              {t('pages.stats.lowbattery.empty')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default LowBatteryDevicesPage;
