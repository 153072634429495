import Avatar from 'react-avatar';
import Loader from 'react-loaders'
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import DeviceImage from "../DeviceImage";

const EntityCard = ({ data, type, tabIndex, selected, disabled, onToggle }) => {
    return (
        type === 'device' ?
            <div className="flex flex-row items-center bg-gray-900 bg-opacity-60 shadow-lg rounded-lg gap-4 p-4">
                <div className='flex flex-col items-center justify-center px-2'>
                    <DeviceImage model={data.product_model} width={24} />
                </div>
                <div className='flex flex-col grow'>
                    <span className="text-gray-500 text-xs">
                        { data.friendly_name }
                    </span>
                    {
                        data.location ?
                            <span className="text-gray-400 text-sm">
                                { data.location }
                            </span>
                        :
                            null
                    }
                </div>
                <div className='flex flex-col items-center justify-center pr-2'>
                    <input type="checkbox" disabled={disabled} checked={selected} onClick={onToggle} class={`h-6 w-6 dark:bg-gray-700 disabled:opacity-60 ${tabIndex === 1?'checked:dark:bg-green':'checked:dark:bg-red-500'} rounded shadow`} />
                </div>
            </div>
        :
            <div className="flex flex-row items-center bg-gray-900 bg-opacity-60 shadow-lg rounded-lg gap-4 p-4">
                <div className='flex flex-col items-center justify-center px-2'>
                    <Avatar maxInitials={2} name={data?.name} round={true} size={36} />
                </div>
                <div className='flex flex-col grow'>
                    <span className="text-gray-400">
                        { data.name }
                    </span>
                    {
                        data.username ?
                            <span className="text-gray-500 text-xs">
                                { data.username }
                            </span>
                        :
                            null
                    }
                </div>
                <div className='flex flex-col items-center justify-center pr-2'>
                    {
                        selected && disabled ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className='animate-spin text-gray-400'><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                        :
                            <input type="checkbox" disabled={disabled} checked={selected} onClick={onToggle} class={`h-6 w-6 dark:bg-gray-700 disabled:opacity-60 ${tabIndex === 1?'checked:dark:bg-green':'checked:dark:bg-red-500'} rounded shadow`} />
                    }
                </div>
            </div>
    )
}


const AssignmentModal = ({
    open,
    onClose,
    loading,
    data,
    dataType = 'device',
    entityID,
    entityAttribute = '',
    onAssign,
    onUnassign,
    clear,
    title = 'Modal',
    assignedTabLabel = 'Assigned',
    unassignedTabLabel = 'Unassigned',
    assignButtonLabel = 'Assign',
    unassignButtonLabel = 'Unassign',
}) => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [SelectedData, setSelectedData] = useState([]);
    const assignedButtonRef = useRef(null);
    
    const sortedData = useMemo(() => {
        if (!data || !Array.isArray(data)) return [];
        return data.filter(d => {
            if (tabIndex === 0) {
                return Array.isArray(d[entityAttribute]) && d[entityAttribute].includes(entityID);
            } else {
                return !Array.isArray(d[entityAttribute]) || !d[entityAttribute].includes(entityID);
            }
        });
    }, [data, tabIndex, entityAttribute, entityID]);

    const toggleSelectAllData = useCallback(() => {
        if (SelectedData.length === sortedData.length) {
            setSelectedData([]);
        } else {
            setSelectedData(sortedData.map(d => d.id));
        }
    }, [SelectedData, sortedData]);

    const toggleSelectData = useCallback((id) => {
        if (SelectedData.includes(id)) {
            setSelectedData(SelectedData.filter(d => d !== id));
        } else {
            setSelectedData([...SelectedData, id]);
        }
    }, [SelectedData]);

    const handleClose = useCallback(() => {
        if (!loading) {
            setSelectedData([]);
            setTabIndex(0);
            onClose();
        }
    }, [onClose, loading]);

    const handleClear = useCallback(() => {
        setSelectedData([]);
    }, [setSelectedData]);

    const handleAssign = useCallback(() => {
        if (SelectedData.length === 0) return;
        onAssign(SelectedData);
    }, [SelectedData, onAssign]);

    const handleUnassign = useCallback(() => {
        if (SelectedData.length === 0) return;
        onUnassign(SelectedData);
    }, [SelectedData, onUnassign]);

    useEffect(() => {
        clear.current = handleClear;
    }, []);

    return (
        <Transition appear show={open} as={React.Fragment}>
            <Dialog
                as="div"
                initialFocus={assignedButtonRef}
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={handleClose}
            >
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-400 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90 backdrop-blur-[1px]" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-200 dark:bg-gray-800 shadow-xl rounded-lg">
                        <Dialog.Title
                            as="h3"
                            className="font-medium leading-6 text-gray-900 dark:text-gray-300 flex flex-row justify-between gap-2 items-center"
                        >
                            <div className='flex flex-row items-center gap-3'>
                                <span>{ title }</span>
                                {
                                    SelectedData.length > 0 ?
                                        <div className={`flex flex-col items-center justify-center ${ tabIndex === 0 ? 'bg-red-500':'bg-green' } px-2 py-0.5 rounded text-xs`}>
                                            { SelectedData.length }
                                        </div>
                                    :
                                        null
                                }
                                {
                                    sortedData.length > 0 ?
                                        <button disabled={loading} className='px-2 py-0.5 disabled:opacity-60 rounded bg-gray-700 text-xs' onClick={toggleSelectAllData}>
                                            {
                                                SelectedData.length === sortedData.length ?
                                                    'Uncheck All'
                                                :
                                                    'Check All'
                                            }
                                        </button>
                                    :
                                        null
                                }
                            </div>
                            {
                                !loading ?
                                    <button onClick={handleClose} className="outline-none">
                                        <XIcon className="w-5 h-5 text-gray-400" />
                                    </button>   
                                :
                                    null
                            }
                        </Dialog.Title>
                        <div className="mt-4" />
                        {
                            (!data || !Array.isArray(data)) ?
                                <div className="flex flex-col grow min-h-[300px] w-full">
                                    <div className="flex flex-col flex-grow items-center justify-center h-full">
                                        <div className="flex flex-col justify-center items-center">
                                            <Loader type="line-scale" active={true} />
                                            <p className="mt-3 text-gray-600 text-center">
                                                {t('loading')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            :
                                <>
                                    <div className="flex flex-col grow min-h-[300px] max-h-[300px] w-full">
                                        <div className="flex flex-row gap-2 items-center bg-gray-900 bg-opacity-60 text-gray-400 rounded-lg p-2">
                                            {/* Assigned & Unassigned Tabs */}
                                            <button disabled={loading} onClick={() => { setTabIndex(0); setSelectedData([]); }} className={`flex flex-row grow p-1.5 disabled:opacity-60 ${(tabIndex === 0)?'bg-gray-800 shadow-lg':''} rounded justify-center cursor-pointer`} ref={assignedButtonRef}>
                                                { t('modals.assignment_modal.assigned_title') }
                                            </button>
                                            <button disabled={loading} onClick={() => { setTabIndex(1); setSelectedData([]); }} className={`flex flex-row grow p-1.5 disabled:opacity-60 ${(tabIndex === 1)?'bg-gray-800 shadow-lg':''} text-center rounded justify-center cursor-pointer`}>
                                                { t('modals.assignment_modal.unassigned_title') }
                                            </button>
                                        </div>
                                        <div className="flex flex-col grow gap-3 overflow-y-auto mt-4">
                                            {
                                                sortedData.map((e) => (
                                                    // Device Card
                                                    <EntityCard data={e} type={dataType} tabIndex={tabIndex} disabled={loading} selected={SelectedData.includes(e.id)} onToggle={() => toggleSelectData(e.id)} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {
                                        tabIndex === 0 ?
                                            <div className='flex flex-col w-full'>
                                                <button onClick={handleUnassign} disabled={SelectedData.length === 0 || loading} className='px-4 py-3 flex flex-row justify-center text-white bg-red-600 bg-opacity-80 disabled:opacity-40 disabled:select-none transition-all rounded-lg'>
                                                    {
                                                        loading ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className='animate-spin'><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                                                        :
                                                            unassignButtonLabel
                                                    }
                                                </button>
                                            </div>
                                        :
                                            <div onClick={handleAssign} className='flex flex-col w-full'>
                                                <button disabled={SelectedData.length === 0 || loading} className='px-4 py-3 flex flex-row justify-center text-white bg-green hover:bg-gree-n disabled:opacity-40 disabled:select-none transition-all rounded-lg'>
                                                    {
                                                        loading ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className='animate-spin'><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                                                        :
                                                            assignButtonLabel
                                                    }
                                                </button>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default AssignmentModal;