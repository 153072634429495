import React, { useState, useEffect } from "react";
import Loader from 'react-loaders'
import Logo from '../assets/logo.svg'

const SplashScreen = ({ children, startAsync, show, onFinish, onError }) => {
  const [Ready, setReady] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await startAsync();
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        if (onError) {
          onError(err);
        }
      } finally {
        setReady(true)
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {
        (Ready && !show) ?
          children
        :
          <div className="flex flex-grow flex-col items-center justify-center h-full dark:bg-gray-800">
            <div className="flex flex-col items-center justify-center">
              <img src={Logo} className="w-20" height="100%" alt="RP Smart" />
              <Loader type="ball-pulse" style={{transform: 'scale(0.8)'}} className="mt-10" />
            </div>
          </div>
      }
    </>
  )
}

export default SplashScreen