import React, { useState } from "react"
import { ExclamationIcon } from "@heroicons/react/solid";
import { Form, Formik } from 'formik';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from '../components/forms/Button'
import InputWithLabel from '../components/formik/InputWithLabel'
import Logo from "../assets/logo.svg"
import { BASE_URL } from "../api/config";
import { login } from "../redux/auth";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(null);

  const submit = async (email, password) => {
    setLoading(true);
    // Create login fetch request 
    const response = await fetch(`${BASE_URL}/auth/organization/login`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    // Call login reducer if successful
    if (response.status >= 200 && response.status <= 299) {
      const { access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key } = await response.json();
      // store the new token
      dispatch(login({ access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key }));
      // navigate to dashboard
      navigate("/");
    } else {
      // If client error >= 400 and <= 500, set error message
      if (response.status >= 400 && response.status <= 499) {
        // If json parse fails, set error as body
        try {
          const msg = await response.json();
          setError(msg.message);
          setLoading(false);
          return msg;
        } catch (e) {
          setError(response.statusText);
          setLoading(false);
          return response.statusText;
        }
      } else {
        // If server error >= 500, set status text
        setError(response.statusText);
        setLoading(false);
        return response.statusText;
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center -mt-30 w-full max-w-xs px-4">
      <img src={Logo} height="100%" className="w-20 mb-8" alt="RP Smart" />
      {
        (Error) ?
        <div className="flex flex-row w-full items-center justify-start bg-yellow-500 text-white mt-2 mb-6 py-2 px-4 rounded font-semibold">
          <ExclamationIcon className="w-5 h-5 mr-2" />
          { Error }
        </div>
        :
        null
      }
      <Formik
        handleBlur={() => {
          setError(null);
        }}
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={async (values) => {
          setError(null);
          await submit(values.email, values.password)
        }}
      >
        <Form className="grid grid-flow-col grid-cols-12 grid-rows-3 w-full">
          <div className="col-span-12">
            <InputWithLabel
              disabled={Loading}
              title="Email address"
              type="email"
              name="email"
              id="email"
              placeholder="work@email.com"
              autoComplete="email"
              required
            />
          </div>
          <div className="col-span-12 mt-1">
            <InputWithLabel
              disabled={Loading}
              title="Password"
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              autoComplete="password"
              required
            />
          </div>
          <div className="col-span-12 mt-4">
            <Button type="submit" loading={Loading} className="w-full">
              Login
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default LoginPage