import React from "react";
import BaseLoader from "./BaseLoader";

const BinLoader = (props) => {
  return (
    <div {...props}>
      <BaseLoader viewBox={"0 0 90 150"} style={{ width: 90, height: 150 }}>
        <rect x="0" y="0" rx="12" ry="12" width="90" height="150" />
      </BaseLoader>
    </div>
  )
}

export default BinLoader