import React from "react"
import { Link } from "react-router-dom"
import { InformationCircleIcon, ExclamationIcon } from "@heroicons/react/solid"
import { useDismissNotificationMutation } from "../redux/services/api"


const NotificationContainer = ({ notification, children }) => {
  return (
    <React.Fragment>
      {/* Use Link tag if notification has device variable, otherwise use div tag */}
      { notification.device ? (
        <Link
          to={`/device/${notification.device}`}
          className="relative flex flex-row items-center justify-between px-4 py-4 text-sm font-semibold rounded bg-gray-200 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
        >
          {children}
        </Link>
      ) : (
        <div
          className="relative flex flex-row items-center justify-between px-4 py-4 text-sm font-semibold rounded bg-gray-200 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
        >
          {children}
        </div>
      )}
    </React.Fragment>
  )
}

const NotificationTile = ({ notification }) => {
  const [dismissNotification, {
    isLoading: loadingDismissNotification
  }] = useDismissNotificationMutation();

  return (
    <div className="flex flex-col">
      <NotificationContainer notification={notification}>
        {/* Show dot if notification is unread */}
        {!notification.read && (
          <div className="absolute top-2 right-2 rounded-full w-1 h-1 bg-blue-500" />
        )}
        <div className="flex flex-row flex-grow items-center gap-4">
          <div className="flex flex-row flex-grow-0">
            {/* Use InformationCircleIcon for device_notification type, ExclamationIcon for device_exception with red text or Generic Icon for rest of notifications */}
            {notification.type === "device_notification" ? (
              <InformationCircleIcon className="w-6 h-6 text-yellow-500" />
            ) : notification.type === "device_exception" ? (
              <ExclamationIcon className="w-6 h-6 text-red-500" />
            ) : (
              <InformationCircleIcon className="w-6 h-6 text-yellow-500" />
            )}
          </div>
          <div className="flex flex-row flex-grow text-sm font-semibold text-gray-700 dark:text-gray-300">
            { notification.message }
          </div>
          {/* Show close button if dismissable */}
          {notification.dismissable && (
            <div className="flex flex-row flex-grow-0">
              <button disabled={loadingDismissNotification} onClick={(e) => { dismissNotification(notification.id); e.preventDefault(); }} className="p-1 rounded-full bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 text-gray-600 dark:text-gray-400 focus:outline-none focus:shadow-outline">
                {
                  // Show loading spinner if dismissNotification is loading else show X icon
                  loadingDismissNotification ? (
                    <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )
                }
              </button>
            </div>
          )}
        </div>
      </NotificationContainer>
      <div className="flex flex-row justify-end text-xs text-gray-700 dark:text-gray-500 mt-1">
        { new Date(notification.created_at).toLocaleString() }
      </div>
    </div>
  )
}

export default NotificationTile;
