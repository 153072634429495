import React from "react"
import { InformationCircleIcon } from "@heroicons/react/solid"
import { CubeTransparentIcon } from "@heroicons/react/outline"
import { useFindAllNotificationsQuery, useReadNotificationsMutation } from "../redux/services/api"
import Loader from 'react-loaders'

import NotificationsList from "../components/NotificationsList"
import { useTranslation } from "react-i18next"

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { data: notifications, isSuccess, refetch } = useFindAllNotificationsQuery()
  const [readNotifications] = useReadNotificationsMutation()

  // Send read notifications to server to update read status upon data loaded
  React.useEffect(() => {
    if (isSuccess && notifications && refetch) {
      // Create array of unread notifications
      const unreadNotifications = notifications.filter(notification => !notification.read)
      // Send notifications if there are unread notifications
      if (unreadNotifications.length > 0) {
        // Dispatch read notifications mutation
        readNotifications(unreadNotifications.map(notification => notification.id));
        setTimeout(() => refetch(), 2000);
      }
    }
  }, [isSuccess, notifications])

  return (
    <div className="flex flex-col flex-grow px-4 my-2">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row">  
          <h1 className="text-2xl font-bold text-gray-700 dark:text-gray-200 mr-4">
            {t('pages.notifications.title')}
          </h1>
        </div>
        {/* Show clear all button if notifications isSuccess and length > 0 */}
        {/* {
          (isSuccess && notifications.length > 0) ?
            (
              <div>
                <button
                  className="flex flex-row items-center justify-center p-1 px-3 text-sm font-semibold rounded-full bg-gray-200 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
                  onClick={() => {}}
                >
                  Clear all
                </button>
              </div>
            )
          : 
            null
        } */}
      </div>
      {/* Show notifications when loaded */}
      { isSuccess ? (
        // Show notifications if available else show empty icon
        (notifications.length > 0) ? (
          <div className="flex flex-col flex-grow mt-6">
            <NotificationsList items={(notifications)?notifications:[]} itemsPerPage={25} />
          </div>
        ) : (
          <div className="flex flex-col flex-grow items-center justify-center">
            <div className="flex flex-col justify-center items-center md:-mt-8">
              <CubeTransparentIcon className="w-10 h-10 dark text-gray-400 dark:text-gray-500" />
              <p className="mt-3 text-gray-400 dark:text-gray-600 text-center">
                {t('pages.notifications.empty')}
              </p>
            </div>
          </div>
        )
      ) : (
        <div className="flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col justify-center items-center md:-mt-8">
            <Loader type="line-scale" active={true} />
            <p className="mt-3 text-gray-300 dark:text-gray-600 text-center">
              {t('loading')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationsPage;