import { useCallback } from "react";
import BinSVG from "./BinSVG";

const DeviceMarker = ({ device, navigate }) => {
    const click = useCallback(() => {
        navigate(device.id);
    }, [device]);

    return (
        <div className="relative rounded-full bg-green bg-opacity-60 shadow-md flex flex-col justify-center items-center opacity-90 cursor-pointer" onClick={click} style={{ width: 48, height: 48 }}>
            <div className='absolute -top-7 left-0 right-0 w-28 text-sm text-center text-black dark:text-white drop-shadow-2xl -ml-7'>
                {device.friendly_name}
            </div>
            <BinSVG fill={device.statistics.device.fill_level} notification_threshold={device.notifications?.fill?.threshold} threshold={device.settings.fill.threshold} width="32px" height="32px" />
        </div>
    )
};

export default DeviceMarker;
