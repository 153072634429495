import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import authReducer from "./auth";
import historyBarReducer from "./historybar";
import mapReducer from "./map";
import overviewBarReducer from "./overviewbar";
import searchReducer from "./search";
import sidebarReducer from "./sidebar";
import themeReducer from "./theme";
import { RPSmartAPI } from "./services/api";

const rootReducer = combineReducers({
	theme: themeReducer,
	auth: authReducer,
	map: mapReducer,
	historybar: historyBarReducer,
	overviewbar: overviewBarReducer,
	sidebar: sidebarReducer,
	search: searchReducer,
	[RPSmartAPI.reducerPath]: RPSmartAPI.reducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(RPSmartAPI.middleware),
});

setupListeners(store.dispatch);

export default store;