import React from "react"

const AuthLayout = ({ children }) => {

  return (
    <div className="flex flex-grow flex-col items-center justify-center h-full dark:bg-gray-800">
      <div className="flex flex-col flex-grow items-center justify-center w-full">
        { children }
      </div>
      <div className="flex flex-col sm:flex-row justify-between my-4 px-4 w-full">
        <div className="flex flex-row">
          <span className="text-xs text-gray-400 dark:text-gray-500 no-underline mr-4">
            RP Smart is a product of Roros Produkter. Copyright &copy; 2022 All Rights Reserved.
          </span>
        </div>
        <div className="flex flex-row mt-3 sm:mt-0">
          <a href="/privacy" className="text-xs text-gray-400 dark:text-gray-500 no-underline mr-6">
            Privacy Policy
          </a>
          <a href="/terms" className="text-xs text-gray-400 dark:text-gray-500 no-underline">
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout