import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../../api/config";

const updateFindAllGeoreferenceCache = ({ dispatch, API, georeferenceID, data }) => {
	dispatch(
		API.util.updateQueryData("findAllGeoreferencing", undefined, (draft) => {
			for (let geo of draft) {
				if (geo.id === georeferenceID) {
					Object.assign(geo, data);
				} 
			}
		})
	);
};

const updateFindAllEmployeeCache = ({ dispatch, API, employeeID, data }) => {
	dispatch(
		API.util.updateQueryData("findAllEmployees", undefined, (draft) => {
			for (let employee of draft) {
				if (employee.id === employeeID) {
					Object.assign(employee, data);
				} 
			}
		})
	);
};

const updateFindAllDeviceCache = ({ dispatch, API, deviceID, data }) => {
	dispatch(
		API.util.updateQueryData("findAllDevices", undefined, (draft) => {
			for (let device of draft) {
				if (device.id === deviceID) {
					Object.assign(device, data);
				} 
			}
		})
	);
};

const updateFindAllGroupCache = ({ dispatch, API, groupID, data }) => {
	dispatch(
		API.util.updateQueryData("findAllGroups", undefined, (draft) => {
			for (let group of draft) {
				if (group.id === groupID) {
					Object.assign(group, data);
				} 
			}
		})
	);
};


export const RPSmartAPI = createApi({
	reducerPath: "RPSmartAPI",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["Profile", "ProfileStatsOverview", "Georeferencing", "Georeference", "Employees", "Employee", "Notifications", "Groups", "Group", "Devices", "Device", "DeviceHistory"],
	endpoints: (builder) => ({
		getProfile: builder.query({
			query: () => ({ url: "user/profile" }),
			providesTags: ["Profile"]
		}),
		getStatsOverview: builder.query({
			keepUnusedDataFor: 300000,
			queryFn: ({ start, end }, API) => {
				if (start !== null && end !== null) {
					return baseQueryWithReauth({ url: `user/overview?start=${start}&end=${end}` },	API);
				} else {
					return { error: 'Invalid date range' };
				}
			},
			providesTags: (result, error, { start, end }) => [{ type: "ProfileStatsOverview", id: `${start}-${end}` }]
		}),
		updateMapCoordinates: builder.mutation({
			query: ({ zoom, latitude, longitude, pitch, bearing }) => ({
				url: "user/map",
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					zoom,
					latitude,
					longitude,
					pitch,
					bearing
				},
			}),
			invalidatesTags: ["Profile"]
		}),
		verifyEmail: builder.mutation({
			query: ({ code }) => ({
				url: "auth/organization/verify",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					code
				}
			}),
			invalidatesTags: ["Profile"]
		}),
		resendVerify: builder.mutation({
			query: () => ({
				url: "auth/organization/resend",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			invalidatesTags: ["Profile"]
		}),

		/* Georeferencing API */
		findAllGeoreferencing: builder.query({
			query: () => ({ url: "georeference" }),
			providesTags: ["Georeferencing"]
		}),
		getGeoreferencing: builder.query({
			query: (id) => ({ url: `georeference/${id}` }),
			providesTags: (result, error, id) => [{ type: "Georeference", id }]
		}),
		createGeoreferencing: builder.mutation({
			query: ({ name, coordinates }) => ({
				url: "georeference",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name,
					coordinates
				}
			}),
			invalidatesTags: ["Georeferencing"]
		}),
		updateGeoreferencing: builder.mutation({
			query: ({ id, name, coordinates }) => ({
				url: `georeference/${id}`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name,
					coordinates
				}
			}),
			onQueryStarted({ id, name, coordinates }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getGeoreferencing", id, (draft) => {
						if (name) {
							draft.name = name;
						}
						if (coordinates) {
							draft.coordinates = coordinates;
						}
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllGeoreferenceCache({ dispatch, API: RPSmartAPI, georeferenceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
			invalidatesTags: (result, error, { id }) => [{ type: "Georeference", id }]
		}),
		uploadGeoreferencing: builder.mutation({
			query: (id) => ({
				url: `georeference/${id}`,
				method: "PUT",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
		}),
		deleteGeoreferencing: builder.mutation({
			query: ({ id }) => ({
				url: `georeference/${id}`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			invalidatesTags: ["Georeferencing"]
		}),
		
		/* Employees API */
		findAllEmployees: builder.query({
			query: () => ({ url: "employees" }),
			providesTags: ["Employees"]
		}),
		getEmployee: builder.query({
			query: (id) => ({
				url: `employees/${id}`
			}),
			providesTags: (result, error, id) => [{ type: "Employee", id }]
		}),
		addEmployee: builder.mutation({
			query: ({ name, username, password }) => ({
				url: "employees",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name,
					username,
					password
				}
			}),
			invalidatesTags: ["Employees"]
		}),
		updateEmployee: builder.mutation({
			query: ({ id, name, password }) => ({
				url: `employees/${id}`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name,
					password
				}
			}),
			invalidatesTags: (result, error, id) => [{ type: "Employee", id }],
			onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getEmployee", id, (draft) => {
						if (name) {
							draft.name = name;
						}
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllEmployeeCache({ dispatch, API: RPSmartAPI, employeeID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		removeEmployee: builder.mutation({
			query: ({ id }) => ({
				url: `employees/${id}`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			invalidatesTags: ["Employees"],
			onQueryStarted({ id }, { dispatch, queryFulfilled }) {
				queryFulfilled.then(() => {
					dispatch(
						RPSmartAPI.util.updateQueryData("Employees", undefined, (draft) => {
							draft.forEach((notification, index) => {
								if (notification.id === id) {
									draft.splice(index, 1);
								}
							});
						})
					);
				}).catch(() => {
					return
				});
			}
		}),

		/* Notifications API */
		findAllNotifications: builder.query({
			query: () => ({ url: "notifications" }),
			keepUnusedDataFor: 15,															// Make sure we always fetch new notifications ( This works in conjunction with notification handler which invalidates "Notifications" tag when we receive a new notification )
			providesTags: () => ["Notifications"]
		}),
		dismissNotification: builder.mutation({
			query: (id) => ({
				url: `notifications/${id}`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				queryFulfilled.then(() => {
					dispatch(
						RPSmartAPI.util.updateQueryData("Notifications", undefined, (draft) => {
							draft.forEach((notification, index) => {
								if (notification.id === id) {
									draft.splice(index, 1);
								}
							});
						})
					);
				}).catch(() => {
					return
				});
			},
			invalidatesTags: ["Notifications"]
		}),
		readNotifications: builder.mutation({
			query: (ids) => ({
				url: "notifications/read",
				method: "PUT",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					notifications: ids
				}
			})
		}),

		/* Global Settings API */
		updateGlobalSettingsMOSE: builder.mutation({
			query: ({ settings, notifications }) => ({
				url: "user/global/mose",
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					settings,
					notifications
				}
			}),
			onQueryStarted({ settings, notifications }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getProfile", undefined, (draft) => {
						draft.global_settings.mose.settings.polling.interval = settings.polling.interval;
						draft.global_settings.mose.settings.fill.threshold = settings.fill.threshold;
						draft.global_settings.mose.notifications.temperature.enabled = notifications.temperature.enabled;
						draft.global_settings.mose.notifications.temperature.threshold = notifications.temperature.threshold;
						draft.global_settings.mose.notifications.weight.enabled = notifications.weight.enabled;
						draft.global_settings.mose.notifications.weight.threshold = notifications.weight.threshold;
					})
				);

				queryFulfilled.catch(() => {
					patchResult.undo();
				});
			},
			invalidatesTags: ["Profile"]
		}),

		/* Groups API */
		findAllGroups: builder.query({
			keepUnusedDataFor: 60000,
			query: () => ({ url: "groups" }),
			providesTags: () => ["Groups"]
		}),
		getGroup: builder.query({
			query: (id) => ({ url: `groups/${id}` }),
			providesTags: (result, error, id) => [{ type: "Group", id }]
		}),
		createGroup: builder.mutation({
			query: ({ name }) => ({
				url: "groups",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name
				}
			}),
			invalidatesTags: ["Groups"]
		}),
		editGroupName: builder.mutation({
			query: ({ id, name }) => ({
				url: `groups/${id}/name`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Group", id }]:[],
			onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getGroup", id, (draft) => {
						draft.name = name;
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllGroupCache({ dispatch, API: RPSmartAPI, groupID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		assignDevicesToGroup: builder.mutation({
			query: ({ groupID, deviceIDs }) => ({
				url: `groups/${groupID}/devices`,
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					devices: deviceIDs
				}
			}),
			invalidatesTags: (result, error, { groupID }) => (!error)?[{ type: "Group", id: groupID }, "Devices"]:[]
		}),
		unassignDevicesFromGroup: builder.mutation({
			query: ({ groupID, deviceIDs }) => ({
				url: `groups/${groupID}/devices`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					devices: deviceIDs
				}
			}),
			invalidatesTags: (result, error, { groupID }) => (!error)?[{ type: "Group", id: groupID }, "Devices"]:[]
		}),
		assignEmployeesToGroup: builder.mutation({
			query: ({ groupID, employeeIDs }) => ({
				url: `groups/${groupID}/employees`,
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					employees: employeeIDs
				}
			}),
			invalidatesTags: (result, error, { groupID }) => (!error)?[{ type: "Group", id: groupID }, "Employees"]:[]
		}),
		unassignEmployeesFromGroup: builder.mutation({
			query: ({ groupID, employeeIDs }) => ({
				url: `groups/${groupID}/employees`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					employees: employeeIDs
				},
			}),
			invalidatesTags: (result, error, { groupID }) => (!error)?[{ type: "Group", id: groupID }, "Employees"]:[]
		}),
		deleteGroup: builder.mutation({
			query: (id) => ({
				url: `groups/${id}`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			invalidatesTags: (result, error) => (!error)?["Groups", "Devices"]:[]
		}),

		/* Devices API */
		findAllDevices: builder.query({
			keepUnusedDataFor: 60000,
			query: () => ({ url: "devices" }),
			providesTags: () => ["Devices"]
		}),
		getDevice: builder.query({
			query: (id) => ({ url: `devices/${id}` }),
			keepUnusedDataFor: 10,
			providesTags: (result, error, id) => (result)?[{ type: "Device", id }]:[],
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				});
			}
		}),
		getDeviceHistory: builder.query({
			// 5 minutes
			keepUnusedDataFor: 300000,
			queryFn: ({ id, start, end }, API) => {
				if (start !== null && end !== null) {
					return baseQueryWithReauth({ url: `devices/${id}/history?start=${start}&end=${end}` },	API);
				} else {
					return { error: 'Invalid date range' };
				}
			},
			providesTags: (result, error, { id, start, end }) => (result)?[{ type: "DeviceHistory", id: `${id}-${start}-${end}` }]:[],
		}),
		updateDeviceCoordinates: builder.mutation({
			query: ({ id, latitude, longitude }) => ({
				url: `devices/${id}/coordinates`,
				method: "PATCH",
				headers: {	
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					latitude,
					longitude
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }, "Devices"]:[],
			onQueryStarted({ id, latitude, longitude }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						draft.map = {};
						draft.map.lat = latitude;
						draft.map.lng = longitude;
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		editDeviceName: builder.mutation({
			query: ({ id, name }) => ({
				url: `devices/${id}/name`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					name
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }]:[],
			onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						draft.friendly_name = name;
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		editDeviceLocation: builder.mutation({
			query: ({ id, location }) => ({
				url: `devices/${id}/location`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					location
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }]:[],
			onQueryStarted({ id, location }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						draft.location = location;
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			}
		}),
		editDeviceBinType: builder.mutation({
			query: ({ id, type }) => ({
				url: `devices/${id}/bintype`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					type
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }]:[],
			onQueryStarted({ id, type }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						draft.bin_type = type;
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			}
		}),
		updateSettings: builder.mutation({
			query: ({ id, polling, fill }) => ({
				url: `devices/${id}/settings`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					polling,
					fill
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }]:[],
			onQueryStarted({ id, polling, fill }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						if (polling) {
							draft.settings.polling.interval = polling.interval;
						}
						if (fill) {
							draft.settings.fill.threshold = fill.threshold;
						}
					})
				);

				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, groupID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		updateNotifications: builder.mutation({
			query: ({ id, fill, temperature, weight }) => ({
				url: `devices/${id}/notifications`,
				method: "PATCH",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					fill,
					temperature,
					weight
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }]:[],
			onQueryStarted({ id, fill, temperature, weight }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					RPSmartAPI.util.updateQueryData("getDevice", id, (draft) => {
						if (fill) {
							if (draft.notifications?.fill) {
								draft.notifications.fill.threshold = fill.threshold;
							} else {
								draft.notifications.fill = {
									threshold: fill.threshold
								};
							}
						}
						if (temperature) {
							draft.notifications.temperature.enabled = temperature.enabled;
							draft.notifications.temperature.threshold = temperature.threshold;
						}
						if (weight) {
							draft.notifications.weight.enabled = weight.enabled;
							draft.notifications.weight.threshold = weight.threshold;
						}
					})
				);
				
				queryFulfilled.then(({ data }) => {
					updateFindAllDeviceCache({ dispatch, API: RPSmartAPI, deviceID: id, data });
				}).catch(() => {
					patchResult.undo();
				});
			},
		}),
		assignEmployeesToDevice: builder.mutation({
			query: ({ id, employees }) => ({
				url: `devices/${id}/employees`,
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					employees
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }, "Employees"]:[],
		}),
		unassignEmployeesFromDevice: builder.mutation({
			query: ({ id, employees }) => ({
				url: `devices/${id}/employees`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					employees
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }, "Employees"]:[],
		}),
		deleteDevice: builder.mutation({
			query: (id) => ({
				url: `devices/${id}`,
				method: "DELETE",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			}),
			invalidatesTags: (result, error, { id }) => (!error)?[{ type: "Device", id }, "Devices"]:[]
		}),
		registerDevice: builder.mutation({
			query: (product) => ({
				url: "devices",
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: {
					product
				}
			}),
		})
	})
});

export const {
	// Profile
	useLazyGetProfileQuery,
	useGetProfileQuery,
	useGetStatsOverviewQuery,
	useLazyGetStatsOverviewQuery,
	useUpdateMapCoordinatesMutation,
	useVerifyEmailMutation,
	useResendVerifyMutation,
	// Georeferencing
	useFindAllGeoreferencingQuery,
	useLazyFindAllGeoreferencingQuery,
	useGetGeoreferencingQuery,
	useCreateGeoreferencingMutation,
	useUpdateGeoreferencingMutation,
	useUploadGeoreferencingMutation,
	useDeleteGeoreferencingMutation,
	// Employees
	useFindAllEmployeesQuery,
	useLazyFindAllEmployeesQuery,
	useGetEmployeeQuery,
	useAddEmployeeMutation,
	useUpdateEmployeeMutation,
	useRemoveEmployeeMutation,
	// Notifications
	useFindAllNotificationsQuery,
	useLazyFindAllNotificationsQuery,
	useDismissNotificationMutation,
	useReadNotificationsMutation,
	// Global Settings
	useUpdateGlobalSettingsMOSEMutation,
	// Groups
	useFindAllGroupsQuery,
	useLazyFindAllGroupsQuery,
	useGetGroupQuery,
	useCreateGroupMutation,
	useEditGroupNameMutation,
	useAssignDevicesToGroupMutation,
	useUnassignDevicesFromGroupMutation,
	useAssignEmployeesToGroupMutation,
	useUnassignEmployeesFromGroupMutation,
	useDeleteGroupMutation,
	// Devices
	useFindAllDevicesQuery,
	useLazyFindAllDevicesQuery,
	useGetDeviceQuery,
	useGetDeviceHistoryQuery,
	useLazyGetDeviceHistoryQuery,
	useUpdateDeviceCoordinatesMutation,
	useEditDeviceNameMutation,
	useEditDeviceLocationMutation,
	useEditDeviceBinTypeMutation,
	useUpdateSettingsMutation,
	useUpdateNotificationsMutation,
	useAssignEmployeesToDeviceMutation,
	useUnassignEmployeesFromDeviceMutation,
	useDeleteDeviceMutation,
	useRegisterDeviceMutation
} = RPSmartAPI;
