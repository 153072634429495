import React from "react";
import BaseLoader from "./BaseLoader";

const GroupHeaderLoader = (props) => {
  return (
    <div {...props}>
      <BaseLoader viewBox={"0 0 268 28"} style={{ width: 268, height: 28 }}>
        <rect x="0" y="0" rx="4" ry="4" width="268" height="28" />
      </BaseLoader>
    </div>
  )
}

export default GroupHeaderLoader