import React, { useState, useCallback } from "react"
import Loader from 'react-loaders'
import { PencilAltIcon, PencilIcon, PhotographIcon, InformationCircleIcon } from "@heroicons/react/solid"
import { CubeTransparentIcon } from "@heroicons/react/outline"
import { useParams } from "react-router-dom"
import { useDropzone } from 'react-dropzone'
import { useTranslation } from "react-i18next"

import BaseLoader from "../../../components/loaders/BaseLoader"
import EditGeoreferenceNameModal from "../../../components/modals/EditGeoreferenceNameModal"
import EditGeoreferenceCoordinatesModal from "../../../components/modals/EditGeoreferenceCoordinatesModal"
import { useGetGeoreferencingQuery, useUploadGeoreferencingMutation } from "../../../redux/services/api"


const GeoreferencePage = () => {
  const { t } = useTranslation();
  const URLParams = useParams();
  const [uploadingGeoreference, setUploadingGeoreference] = useState(false);
  const [showEditGeoreferenceNameModal, setShowEditGeoreferenceNameModal] = useState(false);
  const [showEditGeoreferenceCoordinatesModal, setShowEditGeoreferenceCoordinatesModal] = useState(false);

  // Get Georeference
  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    refetch
  } = useGetGeoreferencingQuery(URLParams.georeferenceID);

  // Get Presigned URL for S3 from backend
  const [getPresignedURL] = useUploadGeoreferencingMutation();
  
  // Dropzone callback for uploading image
  const onDropAccepted = useCallback(acceptedFiles => {
    (async () => {
      try {
        if (!uploadingGeoreference) {
          setUploadingGeoreference(true);
          const { data } = await getPresignedURL(URLParams.georeferenceID);
          
          // Throw error if presigned URL is not returned
          if (!data) {
            throw new Error('No data returned from getPresignedURL');
          }

          // Upload image to S3
          const upload = await fetch(data.url, {
            method: 'PUT',
            body: acceptedFiles[0]
          });
          
          setTimeout(() => {
            setUploadingGeoreference(false);
            refetch();
          }, 2000);
        }
      } catch (err) {
        console.error(err);
        setUploadingGeoreference(false);
      }
    })();
  }, [uploadingGeoreference, getPresignedURL, URLParams.georeferenceID, refetch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    multiple: false,
    accept: 'image/png',
    maxFiles: 1,
    maxSize: 1000000,
    disabled: uploadingGeoreference
  });

  return (
    <div className="flex flex-col flex-grow mx-5">
      <EditGeoreferenceNameModal open={showEditGeoreferenceNameModal} georeferenceID={(data)?data.id:''} value={(data)?data.name:''} onClose={() => setShowEditGeoreferenceNameModal(false)} />
      <EditGeoreferenceCoordinatesModal open={showEditGeoreferenceCoordinatesModal} georeferenceID={(data)?data.id:''} value={(data)?data.coordinates:[]} onClose={() => setShowEditGeoreferenceCoordinatesModal(false)} />
      {/* Show loader while loading employee */}
      { (isLoading || isFetching) && (
        <>
          <div className="flex flex-row justify-center mt-10">
            <BaseLoader viewBox={"0 0 420 250"} style={{ width: 420, height: 250 }}>
              {/* Create svg circle of 64px */}
              <rect x="0" y="0" rx="12" ry="12" width="420" height="250" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-center my-8">
            <BaseLoader viewBox={"0 0 200 30"} style={{ width: 200, height: 30 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="30" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
        </>
      )}
      {/* Show employee is success */}
      { (!isLoading && !isFetching && isSuccess) && (
        <>
          <div className="flex flex-col mt-2">
            <div style={{ height: 250 }} {...getRootProps({className: `relative dropzone flex flex-row flex-grow border-dashed border-gray-300 dark:bg-gray-800 bg-gray-200 text-gray-400 dark:text-gray-500 rounded-lg transition-all ease-linear ${(!uploadingGeoreference)?'hover:text-gray-500 hover:dark:text-gray-400 cursor-pointer':''} ${(isDragActive)?'border-2':''}` })}>
              <input {...getInputProps()} />
              {/* Show loading overlay if uploadingGeoreference is true */}
              { uploadingGeoreference && (
                <div className="absolute top-0 bottom-0 left-0 right-0 rounded-lg flex flex-col justify-center items-center bg-slate-300 bg-opacity-80">
                  <Loader type="ball-scale-multiple" active={true} />
                </div>
              )}
              {/* Show upload image if data.source == "" */}
              {
                (data.source === "") ?
                  <div className="flex flex-col flex-grow justify-center items-center">
                    <PhotographIcon className="w-10 h-10 mx-auto" />
                    <p className="text-center">{t('pages.settings.georeferencing.upload_title')}</p>
                    <p className="text-center text-xs mt-4">{t('pages.settings.georeferencing.upload_subtitle')}</p>
                  </div>
                :
                  <div className="flex flex-col flex-grow">
                    <img src={data.source} alt="img" className="inline-block h-full mx-auto" />
                  </div>   
              }
            </div>
          </div>
          {
            (data.source !== "") &&
            <div className="mt-4 mb-2 flex flex-row items-center text-xs text-slate-600">
              <InformationCircleIcon className="w-4 h-4 mr-2" />
              {t('pages.settings.georeferencing.upload_alt')}
            </div>
          }
          <div onClick={(e) => { setShowEditGeoreferenceNameModal(true); e.preventDefault()} } className="flex flex-row justify-center items-center my-5 text-gray-600 hover:text-gray-700 dark:text-gray-300 hover:dark:text-gray-400 cursor-pointer">
            <span className="text-2xl font-semibold">
              {data.name}
            </span>
            {/* Edit Icon */}
            <PencilAltIcon className="w-5 h-5 ml-3 mt-0.5 text-gray-400 dark:text-gray-500" />
          </div>
          <div className="flex flex-row justify-between items-center my-4">
            <span className="text-xs uppercase font-semibold text-slate-400">
              {t('pages.settings.georeferencing.coordinates')}
            </span>
            <div>
              <button
                onClick={() => setShowEditGeoreferenceCoordinatesModal(true)}
                className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-800 focus:dark:bg-gray-700 hover:dark:bg-gray-700 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
              >
                <PencilIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="flex flex-col flex-grow mb-10">
              {/* If data.coordinates length == 0 show no coordinates icon */}
              {
                (data.coordinates.length == 0) ?
                  <div className="flex flex-col items-center my-10">
                    <CubeTransparentIcon className="w-10 h-10 text-gray-400 dark:text-gray-600" />
                    <p className="mt-3 text-gray-400 dark:text-gray-600 text-center">
                      {t('pages.settings.georeferencing.empty_coordinates')}
                    </p>
                  </div>
                :
                  <div className="grid grid-cols-1 grid-flow-rows">
                    {/* Show coordinates */}
                    {
                      data.coordinates.map((coordinate, index) => (
                        <div className="flex flex-col gap-4 mt-2" key={index}>
                          <div className="w-full flex flex-row items-center bg-gray-200 dark:bg-gray-800 rounded-lg p-3 justify-between">
                            <input
                              type="numeric"
                              step="0.000000001"
                              min="-90"
                              max="90"
                              value={coordinate.lat}
                              name={`coordinates.${index}.lat`}
                              autoComplete="off"
                              placeholder="lat"
                              required
                              disabled="disabled"
                              className={`px-4 py-1 focus:ring-green-500 border-gray-200 dark:border-gray-900 focus:border-gray-400 dark:bg-gray-900 disabled:opacity-60 dark:text-gray-300 block sm:text-sm border-2  focus:outline-none rounded-md w-full mr-3`}
                            />
                            <input
                              type="numeric"
                              name={`coordinates.${index}.lng`}
                              step="0.000000001"
                              min="-180"
                              max="180"
                              value={coordinate.lng}
                              autoComplete="off"
                              placeholder="lng"
                              required
                              disabled="disabled"
                              className={`px-4 py-1 focus:ring-green-500 border-gray-200 dark:border-gray-900 focus:border-gray-400 dark:bg-gray-900 disabled:opacity-60 dark:text-gray-300 block sm:text-sm border-2  focus:outline-none rounded-md w-full`}
                            />
                          </div>
                        </div>
                      ))
                    }
                </div>
              }
          </div>
        </>
      )}
    </div>
  )
}

export default GeoreferencePage
