import React, { useEffect, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDeleteGroupMutation } from "../../redux/services/api";
import { useTranslation } from "react-i18next";

const DeleteGroupModal = ({ onClose, groupID, open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const [deleteGroup, {
    isLoading: mutationLoading,
    isSuccess: Success,
    isError,  
    error: ErrorMessage,
    reset
  }] = useDeleteGroupMutation();

  const isLoading = mutationLoading || Loading;

  const handleSubmit = async () => {
    setLoading(true);
    if (reset) {
      reset();
    }
    await deleteGroup(groupID);
    setLoading(false);
  }

  const handleClose = () => {
    if (!isLoading)
      onClose();
  }

  useEffect(() => {
    if (isError && ErrorMessage) {
      toast.error(ErrorMessage.message);
      setLoading(false);
    }
  }, [isError, ErrorMessage]);

  useEffect(() => {
    if (Success) {
      navigate('/');
      onClose();
      toast.success(t('modals.delete_group.toast_success'));
    }
  }, [Success]);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-200 dark:bg-gray-800 shadow-xl rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300"
              >
                {t('modals.delete_group.title')}
              </Dialog.Title>
              <div className="mt-2">
                
              </div>
              <p className="text-gray-700 dark:text-gray-400">
                {t('modals.delete_group.description')}
              </p>
              <div className="flex flex-row justify-between mt-5">
                {/* Create cancel button */}
                <button
                  type="button"
                  className=" bg-gray-500 hover:bg-gray-700 text-sm disabled:opacity-40 disabled:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isLoading}
                  onClick={handleClose}
                >
                  {t('modals.delete_group.cancel_action')}
                </button>
                {/* Create custom tailwind button */}
                <button
                  onClick={handleSubmit}
                  className="bg-red-600 hover:bg-red-700 disabled:opacity-40 disabled:bg-red-500 text-sm text-white font-semibold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                  disabled={isLoading}
                >
                  {/* If loading, show svg spinner */}
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    t('modals.delete_group.submit_action')
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default DeleteGroupModal;