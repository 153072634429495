import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
	name: "map",
    initialState: {
        devices: [],
        focus: []
	},
	reducers: {
        setDevices: (state, action) => {
            state.devices = action.payload;
        },
        setFocus: (state, action) => {
            state.focus = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setDevices, setFocus } = mapSlice.actions;

export default mapSlice.reducer;