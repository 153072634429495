import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { Loader } from 'react-loaders';

const DeviceGraph = ({ data, iteratee, label, title, stroke = "#8884d8", fill = "#8884d8", min = 0, max = 100, loading }) => {
  // useMemo is used to avoid re-rendering the chart when the data changes
  const chartData = React.useMemo(() => {
    if (data) {
      return {
        datasets: [{
          label,
          pointRadius: 0,
          borderWidth: 1,
          borderColor: 'rgb(34,141,117)',
          fill: true,
          tension: 0.2,
          backgroundColor:  'rgba(34,141,117, 0.2)',
          data: data.map(item => {
            return {
              x: item.time,
              y: item[iteratee]
            }
          })
        }]
      }
    } else {
      return {
        datasets: []
      };
    }
  }, [data, iteratee, label])

  return (
    <div className='relative flex flex-col flex-grow w-full rounded bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow'>
      {/* Create Loading Spinner overlay */}
      {
        (loading) ?
          <div className="absolute top-0 bottom-0 left-0 right-0 select-none bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80 rounded">
            <div className="flex flex-col flex-grow justify-center items-center h-full">
              <Loader type="line-scale" style={{transform: 'scale(0.8)'}} />
              <p className="text-center text-gray-500 text-sm font-semibold mt-2">Loading</p>
            </div>
          </div>
        :
          null
      }
      <div className='flex flex-row justify-between items-center px-4 mt-4'>
        <h2 className='text-sm font-semibold text-gray-500 dark:text-gray-400 mr-4'>{title}</h2>
      </div>
      <div className='flex flex-col flex-grow p-4'>
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
            },
            scales: {
              x: {
                type: 'timeseries',
                title: {
                  display: false
                },
                ticks: {
                  autoSkip: false,
                  major: {
                    enabled: true
                  },
                  font: function(context) {
                    if (context.tick && context.tick.major) {
                      return {
                        weight: 'bold',
                      };
                    }
                  }
                }
              },
              y: {
                suggestedMin: min,
                suggestedMax: max,
                title: {
                  display: false
                }
              }
            }
          }}
          data={(chartData)?chartData:{ datasets: [] }}
        />
      </div>
    </div>
  );
}

export default DeviceGraph;
