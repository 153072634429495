import React from "react";
import { Dialog, Transition } from '@headlessui/react'
import { Formik, Form } from 'formik';
import InputWithLabel from '../formik/InputWithLabel'
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFindAllEmployeesQuery, useAddEmployeeMutation } from "../../redux/services/api";
import { useTranslation } from "react-i18next";

const AddSchema = Yup.object().shape({
	name: Yup.string().trim().min(2, "Invalid Name").required("Employee name is required"),
	username: Yup.string().trim().min(8, "Minimum 8 characters requried").required("Username is required"),
	password: Yup.string().trim().required("Password is Required").matches(/[0-9]/, "Password should contain atleast 1 digit").matches(/(?=.*?[#?!@$%^&*-])/, "Password should contain atleast 1 special character").matches(/^.{8,}$/, "Password should be of atleast 8 characters")
});

const AddEmployeeModal = ({ onClose, open }) => {
  const { t } = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [addEmployee, {
    isLoading: mutationLoading,
    isSuccess: Success,
    isError,
    error: ErrorMessage,
    reset
  }] = useAddEmployeeMutation();
  const {
    refetch,
  } = useFindAllEmployeesQuery();

  const isLoading = mutationLoading || Loading;

  const handleSubmit = async ({ name, username, password }) => {
    setLoading(true);
    if (reset) {
      reset();
    }
    await addEmployee({
      name,
      username,
      password,
    });
    setLoading(false);
  }

  const handleClose = () => {
    if (!isLoading)
      onClose();
  }

  React.useEffect(() => {
    if (isError && ErrorMessage) {
      toast.error(ErrorMessage.message);
      setLoading(false);
    }
  }, [isError, ErrorMessage]);

  React.useEffect(() => {
    if (Success) {
      refetch();
      onClose();
      toast.success(t('modals.add_employee.toast_success'));
    }
  }, [Success]);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-200 dark:bg-gray-800 shadow-xl rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300"
              >
                {t('modals.add_employee.title')}
              </Dialog.Title>
              <div className="mt-2">
                
              </div>

              <Formik
                validationSchema={AddSchema}
                handleBlur={() => {
                  if (reset) {
                    reset();
                  }
                }}
                initialValues={{
                  name: '',
                  username: '',
                  password: '',
                }}
                onSubmit={async (values) => {
                  await handleSubmit({
                    name: values.name,
                    username: values.username,
                    password: values.password,
                  });
                }}
              >
                {({ touched, errors, isSubmitting, values }) =>
                  <Form className="grid grid-cols-1 grid-flow-rows w-full">
                    <div className="flex flex-col mt-2">
                      <InputWithLabel
                        disabled={isLoading}
                        placeholder={t('modals.add_employee.name_placeholder')}
                        type="text"
                        name="name"
                        id="name"
                        errors={errors.name}
                        autoComplete="off"
                      />
                    </div>
                    <div className="flex flex-col mt-2">
                      <InputWithLabel
                        disabled={isLoading}
                        placeholder={t('modals.add_employee.username_placeholder')}
                        type="text"
                        name="username"
                        id="username"
                        errors={errors.username}
                        autoComplete="off"
                      />
                    </div>
                    <div className="flex flex-col mt-2">
                      <InputWithLabel
                        disabled={isLoading}
                        placeholder={t('modals.add_employee.password_placeholder')}
                        type="password"
                        name="password"
                        id="password"
                        errors={errors.password}
                        autoComplete="off"
                      />
                    </div>
                    <div className="flex flex-row justify-between mt-5">
                      {/* Create cancel button */}
                      <button
                        type="button"
                        className=" bg-gray-500 hover:bg-gray-700 text-sm disabled:opacity-40 disabled:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                        onClick={handleClose}
                      >
                        {t('modals.add_employee.cancel_action')}
                      </button>
                      {/* Create custom tailwind button */}
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 disabled:opacity-40 disabled:bg-blue-500 text-sm text-white font-semibold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                      >
                        {/* If loading, show svg spinner */}
                        {isLoading ? (
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        ) : (
                          t('modals.add_employee.submit_action')
                        )}
                      </button>
                    </div>
                  </Form>
                }
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default AddEmployeeModal;