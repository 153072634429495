import React, { useEffect } from "react"
import { ExclamationIcon, PlusSmIcon } from "@heroicons/react/solid";
import { Scrollbars } from 'react-custom-scrollbars';
import { TrendingUpIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AddGroupModal from "../components/modals/AddGroupModal";
import DeviceList from "../components/DeviceList";
import DeviceTileLoader from "../components/loaders/DeviceTileLoader";
import GroupLoader from "../components/loaders/GroupTileLoader";
import GroupTile from "../components/GroupTile";
import StatTile from "../components/StatTile";
import { setAverageFillLevel, setCompressionFactor, setCompressions, setLoading, setShow, setTimesEmptied } from "../redux/overviewbar";
import { setDevices, setFocus } from "../redux/map";
import { useFindAllDevicesQuery, useFindAllGroupsQuery, useFindAllNotificationsQuery, useGetStatsOverviewQuery } from '../redux/services/api'

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openAddGroup, setOpenAddGroup] = React.useState(false);
  const HistoryBarFrom = useSelector(state => state.historybar.from);
  const HistoryBarTo = useSelector(state => state.historybar.to);

  const {
    data: groups,
    isLoading: loadingGroups,
    isFetching: fetchingGroups,
    isSuccess: isSuccessGroups
  } = useFindAllGroupsQuery();

  const {
    data: devices,
    isLoading: loadingDevices,
    isFetching: fetchingDevices,
    isSuccess: isSuccessDevices
  } = useFindAllDevicesQuery();

  const {
    data: notifications,
    isSuccess: isSuccessNotifications
  } = useFindAllNotificationsQuery();

  const {
    data: stats,
    isLoading: loadingStats,
    isFetching: fetchingStats,
    isSuccess: isSuccessStats,
  } = useGetStatsOverviewQuery({ start: HistoryBarFrom, end: HistoryBarTo });

  React.useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setShow(true));
    return () => {
      // Clear default values
      dispatch(setCompressions(0));
      dispatch(setCompressionFactor(0));
      dispatch(setAverageFillLevel(0));
      dispatch(setTimesEmptied(0));
      dispatch(setShow(false));
      dispatch(setLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set Overview bar to loading if stats are loading or fetching
  React.useEffect(() => {
    if (loadingStats || fetchingStats) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStats, fetchingStats]);

  // Set Overview bar stats when stats are fetched or changed
  React.useEffect(() => {
    if (isSuccessStats) {
      dispatch(setCompressions(stats.compressions));
      dispatch(setCompressionFactor(stats.compression_factor));
      dispatch(setAverageFillLevel(stats.average_fill));
      dispatch(setTimesEmptied(stats.times_emptied));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessStats, stats]);

  // Set All Devices to Map
  useEffect(() => {
    if (!isSuccessDevices || !devices) return;
    dispatch(setDevices(devices.map((d) => d.id)));
    dispatch(setFocus([]));
  }, [devices, isSuccessDevices]);

  // Calculate filled devices
  const filledDevices = (isSuccessDevices)?devices.filter(device => device.statistics.device.fill_level > device.settings.fill.threshold).length:0;
  // Calculate Low battery devices
  const lowBatteryDevices = (isSuccessDevices)?devices.filter(device => device.statistics.battery.percentage <= 15).length:0;
  // Calculate Exceptions in notifications
  const exceptions = (isSuccessNotifications)?notifications.filter(notification => notification.type === 'device_exception').length:0;

  const Groups = () => (isSuccessGroups && groups.length > 0) ? groups.map((g) => <GroupTile key={g.id} group={g} title={g.name} devices={g.devices} />) : <div />;

  return (
    <React.Fragment>
      <AddGroupModal open={openAddGroup} onClose={() => setOpenAddGroup(false)} />
      {
        // Check if filledDevices / lowBatteryDevices / exceptions are available with length > 0
        (filledDevices > 0 || lowBatteryDevices > 0 || exceptions > 0) ?
        <div className="flex flex-wrap xl:flex-nowrap flex-row gap-3 px-4 mt-1 mb-4">
          <StatTile title={t('pages.homepage.tiles.filled')} icon={<TrendingUpIcon className="w-4 h-4 mr-2 text-yellow-500" />} value={filledDevices} to="/stats/filled"/>
          <StatTile
            title={t('pages.homepage.tiles.lowbattery')}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4 mr-2 text-blue-500" fill="currentColor">
                <g data-name="Layer 2">
                  <g data-name="battery">
                    <rect width="24" height="24" opacity="0" />
                    <path
                      d="M15.83 6H4.17A2.31 2.31 0 0 0 2 8.43v7.14A2.31 2.31 0 0 0 4.17 18h11.66A2.31 2.31 0 0 0 18 15.57V8.43A2.31 2.31 0 0 0 15.83 6z" />
                    <path d="M21 9a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1z" />
                  </g>
                </g>
              </svg>
            }
            value={lowBatteryDevices}
            to="/stats/low-battery"
          />
          <StatTile
            title={t('pages.homepage.tiles.exceptions')}
            icon={
              <ExclamationIcon className="w-4 h-4 mr-2 text-red-500" />
            }
            value={exceptions}
            to="/stats/exceptions"
          />
        </div>
        :
        <hr className="mb-4 border-gray-200 dark:border-gray-800" />
      }
      <div className='flex flex-row items-center mr-4 mb-2'>
        <p className="text-xs font-semibold text-gray-400 uppercase px-3">
          {t('pages.homepage.groups.title')}
        </p>
        <button onClick={() => setOpenAddGroup(true)} className="flex flex-row items-center justify-center py-0.5 px-2 text-xs font-semibold rounded-full text-gray-400 bg-gray-200 dark:bg-gray-800 focus:dark:bg-gray-700 hover:dark:bg-gray-700 mr-2 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150">
          <PlusSmIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="relative flex flex-col my-2">
        <div className="grid grid-flow-col grid-rows-1 gap-3 px-4 justify-start soft-scrollbar overflow-x-auto scroll-smooth">
          {
            (loadingGroups || fetchingGroups) ?
            <React.Fragment>
              <GroupLoader className="flex flex-row h-20 flex-grow" />
              <GroupLoader className="flex flex-row h-20 flex-grow" />
              <GroupLoader className="flex flex-row h-20 flex-grow" />
              <GroupLoader className="flex flex-row h-20 flex-grow" />
            </React.Fragment>
            :
            <Groups />
          }
        </div>
        <div className="scroll-shade z-10" />
      </div>
      <p className="text-xs font-semibold text-gray-400 uppercase pb-2 px-3">
        {t('pages.homepage.devices.title')}
      </p>
      <div className="px-4 py-2">
        {
          (loadingDevices || fetchingDevices) ?
          <React.Fragment>
            <DeviceTileLoader className="flex flex-row flex-grow mb-4"/>
            <DeviceTileLoader className="flex flex-row flex-grow mb-4"/>
          </React.Fragment>
          :
          <DeviceList items={devices} itemsPerPage={15} />
        }
      </div>
    </React.Fragment>
  )
}

export default HomePage
