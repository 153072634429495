import React from "react"
import { Link } from "react-router-dom"

const StatTile = ({ title, icon = null, value, to = "#" }) => {
  return (
    <Link to={to} className="flex flex-col w-full xl:w-1/3 p-4 rounded bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow cursor-pointer">
      <div className="flex flex-row flex-grow items-start">
        { icon }
        <span className="text-sm text-gray-500 font-semibold -mt-0.5">
          { title }
        </span>
      </div>
      <span className="text-2xl font-bold text-gray-700 dark:text-gray-200 mt-1">
        { value }
      </span>
    </Link>
  )
}

export default StatTile