import React from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router"

const RequireAuth = ({ children }) => {
  const loggedIn = useSelector(state => state.auth.loggedIn)
  const location = useLocation()

  return (
    <>
      {
        (loggedIn)
        ?
        <>
          { children }
        </>
        :
        <Navigate to="/login" state={{ from: location }} />
      }
    </>
  )
}


const GuestAuth = ({ children }) => {
  const loggedIn = useSelector(state => state.auth.loggedIn)

  return (
    <>
      {
        (!loggedIn)
        ?
        <>
          { children }
        </>
        :
        <Navigate to="/" />
      }
    </>
  )
}

export {
  RequireAuth,
  GuestAuth
}