// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { LngLatBounds } from '!mapbox-gl';
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import * as ReactDOM from "react-dom";
import { InformationCircleIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetProfileQuery, useFindAllGeoreferencingQuery, useUpdateMapCoordinatesMutation, useFindAllDevicesQuery, useUpdateDeviceCoordinatesMutation } from '../redux/services/api';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import DeviceMarker from './DeviceMarker';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXNyb2NrczUiLCJhIjoiY2t3emEwZXFvMDEzNTJwbXh0YTcxcjM3cSJ9.942WTWQsWACKZXpc23oCRA';

const Map = () => {
  // Get Theme
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reloadSourcesInterval = useRef(null);
  const mapLoaded = useRef(false);
  const styleLoaded = useRef(false);
  const theme = useSelector(state => state.theme.style);
  const sidebarOpen = useSelector(state => state.sidebar.open);
  const mapContainer = useRef(null);
  const [lng, setLng] = useState(11.383513856183498);
  const [lat, setLat] = useState(62.57515617998948);
  const [zoom, setZoom] = useState(12);
  const [pitch, setPitch] = useState(0);
  const [bearing, setBearing] = useState(0);

  const selectedDevices = useSelector(state => state.map.devices);
  const selectedFocus = useSelector(state => state.map.focus);

  // Device Coordinator
  const addDeviceOnMove = useRef(false);
  const tempDevice = useRef(null);
  const [mouseLat, setMouseLat] = useState(null);
  const [mouseLng, setMouseLng] = useState(null);

  const map = useRef(null);
  const markers = useRef([]);

  // Get Profile
  const { 
    data: profile,
    isSuccess: isSuccessProfile,
    isFetching: isFetchingProfile,
  } = useGetProfileQuery();

  // Get all devices
  const {
    data: devices,
    isSuccess: isSuccessDevices,
  } = useFindAllDevicesQuery();

  // Get All Georeferencing
  const {
    data: georeferencing,
  } = useFindAllGeoreferencingQuery();

  // Update Map Coordinates
  const [updateMapCoordinates, {
    isLoading: isLoadingUpdateMapCoordinates,
  }] = useUpdateMapCoordinatesMutation();

  // Update Device Coordinates
  const [updateDeviceCoordinates, {
    isLoading: isLoadingUpdateDeviceCoordinates,
  }] = useUpdateDeviceCoordinatesMutation();

  const coordinatesChanged = useMemo(() => {
    if (!profile || !profile?.dashboard?.map) return;
    return (zoom.toFixed(6) !== profile.dashboard.map.zoom.toFixed(6) || lat.toFixed(6) !== profile.dashboard.map.center.lat.toFixed(6) || lng.toFixed(6) !== profile.dashboard.map.center.lng.toFixed(6) || pitch.toFixed(6) !== profile.dashboard.map.pitch.toFixed(6) || bearing.toFixed(6) !== profile.dashboard.map.bearing.toFixed(6));
  }, [profile, zoom, lat, lng, pitch, bearing]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'device',
    drop: (item) => {
      if (addDeviceOnMove.current) return;
      console.log('item: ', item);
      addDeviceOnMove.current = true;
      tempDevice.current = item;
    },
    collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
  }));

  const isDropActive = canDrop && isOver;

  // Map coordinates update function
  const updateCoordinates = useCallback(() => {
    if (mapLoaded.current) {
      // Get map.current zoom, latitude, longitude, pitch and bearing values
      const zoom = map.current.getZoom();
      const lat = map.current.getCenter().lat;
      const lng = map.current.getCenter().lng;
      const pitch = map.current.getPitch();
      const bearing = map.current.getBearing();

      // Update map coordinates
      updateMapCoordinates({
        zoom: zoom,
        latitude: lat,
        longitude: lng,
        pitch: pitch,
        bearing: bearing,
      });
    }
  }, [updateMapCoordinates]);

  // Reload Coordinates function
  const reloadCoordinates = useCallback(() => {
    if (map.current) {
      // Set map zoon, center, pitch and bearing values from profile.dashboard.map
      map.current.flyTo({
        center: [profile.dashboard.map.center.lng, profile.dashboard.map.center.lat],
        zoom: profile.dashboard.map.zoom,
        pitch: profile.dashboard.map.pitch,
        bearing: profile.dashboard.map.bearing,
      }, {
        speed: 0.6,
        maxDuration: 3000
      });
    }
  }, [profile]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/asrocks5/ckwza1nfi12e514o3rdvbutk5',
      center: [lng, lat],
      zoom: zoom,
      maxZoom: 20,
      minZoom: 1,
      // Change logo position
      logoPosition: 'bottom-right',
      // Remove attribution
      attributionControl: false
    });

    map.current.on('load', () => {
      mapLoaded.current = true;
      map.current.on('mousemove', (e) => {
        setMouseLat(e.lngLat.lat);
        setMouseLng(e.lngLat.lng);

        // Add device on move if queued
        if (addDeviceOnMove.current) {
          addDeviceOnMove.current = false;
          updateDeviceCoordinates({
            id: tempDevice.current.id,
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
          });
          tempDevice.current = null;
        }
      });
    });

    map.current.on('style.load', () => {
      styleLoaded.current = true;
    });
  });

  useEffect(() => {
    if (map.current && styleLoaded.current === true && georeferencing) {
      // Clear interval before setting new one
      clearInterval(reloadSourcesInterval.current);

      reloadSourcesInterval.current = setInterval(() => {
        console.log('Reloading Sources');
        if (map.current && map.current.isStyleLoaded() && georeferencing) {
        console.log('Reloading Sources - Loading');
        // Loop through georeferencing and set mapbox image source
          georeferencing.forEach(geo => {
            if (geo.source !== "" && geo.coordinates.length > 0) {
              console.log('Adding geo source: ', geo.id);
              const bounds = geo.coordinates.map(coordinate => [coordinate.lng, coordinate.lat]) || [];
              const latlngbounds = new LngLatBounds(bounds[0], bounds[1]);
    
              // Remove layer if exists
              if (map.current.getLayer(geo.id)) {
                map.current.removeLayer(geo.id);
              }
              
              // Remove old source
              if (map.current.getSource(geo.id)) {
                console.log('Removing old geo source: ', geo.id);
                map.current.removeSource(geo.id);
              }
    
              map.current.addSource(geo.id, {
                type: 'image',
                url: geo.source,
                coordinates: (bounds.length === 4)?
                [
                  ...bounds,
                ]
                  :
                [
                  latlngbounds.getSouthWest().toArray(),
                  latlngbounds.getSouthEast().toArray(),
                  latlngbounds.getNorthEast().toArray(),
                  latlngbounds.getNorthWest().toArray(),
                ]
              });
    
              map.current.addLayer({
                id: `${geo.id}-layer`,
                type: 'raster',
                source: geo.id
              });
            }
          });

          // Reset interval
          clearInterval(reloadSourcesInterval.current);
        }
      }, 500);
      styleLoaded.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [georeferencing, styleLoaded.current]);

  // Change mapbox style
  const changeMapStyle = (style) => {
    map.current.setStyle(style);
  };

  useEffect(() => {
    // Set Mapbox style depending on theme
    if (theme === 'dark') {
      changeMapStyle('mapbox://styles/asrocks5/ckwza1nfi12e514o3rdvbutk5');
    } else {
      changeMapStyle('mapbox://styles/asrocks5/ckxjwu9jjkuhu15o5t8899gxe');
    }
  }, [theme]);

  useEffect(() => {
    if (!map.current || !isSuccessProfile || !profile || !isSuccessDevices || !devices || !selectedFocus || !Array.isArray(selectedFocus)) return; // wait for map to initialize
    // Set zoom and center from profile
    if (selectedFocus.length === 0) {
      if (isSuccessProfile && profile?.dashboard?.map) {
        const center = new mapboxgl.LngLat(profile.dashboard.map.center.lng, profile.dashboard.map.center.lat);
        // Set Pitch
        map.current.flyTo({
          center: center,
          zoom: profile.dashboard.map.zoom,
          pitch: profile.dashboard.map.pitch,
          bearing: profile.dashboard.map.bearing,
        }, {
          maxDuration: 2000,
          speed: 0.8
        });
      }
    } else if (selectedFocus.length > 0) {
      const focusedDevices = devices.filter(device => selectedFocus.includes(device.id));
      if (focusedDevices.length > 0) {
        // Mash every device's lng and lat into a single array
        let bounds = new mapboxgl.LngLatBounds();
        console.log(focusedDevices)
        let validDevices = 0;
        focusedDevices.forEach(device => {
          if (!device?.map) return;
          validDevices++;
          bounds.extend(new mapboxgl.LngLat(device.map.lng, device.map.lat));
        });
        console.log(bounds);
        if (validDevices > 1) {
          map.current.fitBounds(bounds, {
            padding: 200
          });
        } else if (validDevices === 1) {
          map.current.fitBounds(bounds, {
            padding: 200,
            zoom: 18
          }, {
            maxDuration: 10000,
            speed: 0.8
          });
        }
      }
    }
  }, [isSuccessProfile, profile, isSuccessDevices, devices, map.current, selectedFocus])

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
      setPitch(map.current.getPitch());
      setBearing(map.current.getBearing());
    });
  }, [map.current]);

  // Navigate to device callback
  const navigateToDevice = useCallback((deviceId) => {
    navigate(`/device/${deviceId}`);
  }, []);

  // Add all devices to map
  useEffect(() => {
    if (!map.current || !isSuccessDevices || !devices) return;

    // Remove all existing markers
    if (markers.current.length > 0) {
      markers.current.forEach(marker => {
        marker.remove();
      });
      markers.current = [];
    }

    // Filter devices according to selectedDevices
    const filteredDevices = devices.filter(device => selectedDevices.includes(device.id));
    filteredDevices.forEach((device) => {
      if (!device?.map) return;
      // Load Marker
      const element = document.createElement("div");
      ReactDOM.render(<DeviceMarker device={device} navigate={navigateToDevice} />, element);
      const marker = new mapboxgl.Marker(element).setLngLat([device.map.lng, device.map.lat]).addTo(map.current);
      markers.current.push(marker);
    });
  }, [selectedDevices, devices, isSuccessDevices, map.current]);

  useEffect(() => {
    if (!map.current) return;
    // Resize Map
    map.current.resize();
  }, [map.current, sidebarOpen]);

  return (
    <div ref={drop} className='relative w-full h-full z-0'>
      <div ref={mapContainer} className="relative w-full h-full z-0" style={{ minHeight: 300 }}>
        <div className="absolute bottom-0 left-0 m-4 z-20">
          <div className='flex flex-row gap-4'>
            {/* "Adding Device to Map" overlay */}
            {
              isLoadingUpdateDeviceCoordinates && (
                <div className='flex flex-row items-center text-gray-400 text-sm p-4 rounded-lg bg-gray-900 shadow-lg'>
                  <svg className="animate-spin h-4 w-4 mr-3 text-green" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {t('map.adding_device_state')}
                </div>
              )
            }
            {
              isDropActive && (
                <div className='flex flex-row items-center text-gray-400 text-sm p-4 rounded-lg bg-gray-100 dark:bg-gray-900 shadow-lg'>
                  <InformationCircleIcon className='w-5 h-5 mr-3' />
                  {t('map.drop_device_state')}
                </div>
              )
            }
          </div>
          <div className="flex flex-row items-end gap-4">
            {
              // If zoom, lat, lng, pitch and bearing are not equal to values in profile.dashboard.map, show set as default button
              (isSuccessProfile && isSuccessDevices && !isFetchingProfile) &&
                <React.Fragment>
                  {
                    (!profile?.dashboard?.map || coordinatesChanged) &&
                      <div className='mt-4'>
                        <button onClick={updateCoordinates} className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-700 focus:dark:bg-gray-600 hover:dark:bg-gray-600 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150">
                          {
                            isLoadingUpdateMapCoordinates ?
                              <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            :
                              t('map.default_view_action')
                          }
                        </button>
                      </div>
                  }
                  {
                    (profile?.dashboard?.map && coordinatesChanged) ?
                      <div className='mt-4'>
                        <button onClick={reloadCoordinates} className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-700 focus:dark:bg-gray-600 hover:dark:bg-gray-600 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150">
                          {t('map.reset_action')}
                        </button>
                      </div>
                    :
                      null
                  }
                </React.Fragment>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Map