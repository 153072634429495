import React, { useEffect } from "react";
import { CubeTransparentIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useFindAllNotificationsQuery, useReadNotificationsMutation } from "../../redux/services/api";

import { setDevices, setFocus } from "../../redux/map";
import { useDispatch } from "react-redux";

import NotificationsList from "../../components/NotificationsList";
import { useTranslation } from "react-i18next";


const ExceptionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Get all notifications
  const {
    data: notifications,
    isSuccess: notificationsSuccess,
    refetch
  } = useFindAllNotificationsQuery();
  const [readNotifications] = useReadNotificationsMutation()

  // Filter device_exceptions if loaded
  const deviceExceptions = React.useMemo(() => {
    if (notificationsSuccess && notifications) {
      return notifications.filter(notification => notification.type === "device_exception")
    } else {
      return []
    }
  }, [notifications, notificationsSuccess]);
  
  // Send read notifications to server to update read status upon data loaded
  React.useEffect(() => {
    if (notificationsSuccess) {
      // Create array of unread exceptions
      const unreadExceptions = deviceExceptions.filter(notification => !notification.read)
      // Send notifications if there are unread notifications
      if (unreadExceptions.length > 0) {
        // Dispatch read notifications mutation
        readNotifications(unreadExceptions.map(notification => notification.id));
        refetch();
      }
    }
  }, [notificationsSuccess, deviceExceptions, readNotifications, refetch])

  useEffect(() => {
    if (!deviceExceptions) return;
    const exceptionDevices = deviceExceptions.map(exception => exception.device);
    dispatch(setDevices(exceptionDevices));
    dispatch(setFocus(exceptionDevices));
  }, [deviceExceptions]);

  return (
    <div className="flex flex-col flex-grow px-4 my-3">
      {/* Create Filled Device heading with TrendingUpIcon */}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {t('pages.stats.exceptions.title')}
        </h1>
        <div className="flex flex-row items-center">
          <ExclamationIcon className="w-7 h-7 text-red-500" />
          <span className="text-lg text-gray-500 font-semibold ml-2">
            {deviceExceptions.length}
          </span>
        </div>
      </div>
      <p className="mt-2 text-gray-400 dark:text-gray-500">
        {t('pages.stats.exceptions.description')}
      </p>
      {/* Show Device Exceptions List */}
      <div className="flex flex-col my-6">
        <NotificationsList absolute={true} items={deviceExceptions} itemsPerPage={25} />
      </div>
      {/* Show empty icon if there are no devices */}
      {deviceExceptions.length === 0 && (
        <div className="flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col justify-center items-center md:-mt-8">
            <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
            <p className="mt-3 text-gray-600 text-center">
              {t('pages.stats.exceptions.empty')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExceptionsPage;
