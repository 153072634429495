import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Create Async LoginRequest
export const loginRequest = createAsyncThunk("auth/loginRequest", async (payload, { dispatch }) => {
  const { email, password } = payload;

  try {
    // Set Loading true
    dispatch(setLoading(true));

    // Make Fetch Request
    const response = await fetch(`https://portal.rorosprodukter.no/api/auth/organization/login`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    // Call login reducer if successful
    if (response.status >= 200 && response.status <= 299) {
      const msg = await response.json();
      const { access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key } = msg;
      // store the new token
      dispatch(login({ access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key }));
      return msg;
    } else {
      // If client error >= 400 and <= 500, set error message
      if (response.status >= 400 && response.status <= 499) {
        // If json parse fails, set error as body
        try {
          const msg = await response.json();
          dispatch(setError(msg.message));
          return msg;
        } catch (e) {
          dispatch(setError(response.statusText));
          return response.statusText;
        }
      } else {
        // If server error >= 500, set status text
        dispatch(setError(response.statusText));
        return response.statusText;
      }
    }
  } catch (error) {
    return error;
  } finally {
    // Set Loading false
    dispatch(setLoading(false));
  }
});

export const authSlice = createSlice({
	name: "theme",
	initialState: {
    loading: false,
    error: null,
    loggedIn: false,
    access_token: null,
    access_token_expiry: null,
    refresh_token: null,
    refresh_token_expiry: null,
    algolia_key: null
	},
	reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    login: (state, { payload }) => {
      state.access_token = payload.access_token;
      state.access_token_expiry = payload.access_token_expiry;
      state.refresh_token = payload.refresh_token;
      state.refresh_token_expiry = payload.refresh_token_expiry;
      state.algolia_key = payload.algolia_key;
      state.loggedIn = true;
      localStorage.setItem("access_token", state.access_token);
      localStorage.setItem("access_token_expiry", state.access_token_expiry);
      localStorage.setItem("refresh_token", state.refresh_token);
      localStorage.setItem("refresh_token_expiry", state.refresh_token_expiry);
      localStorage.setItem("algolia_key", state.algolia_key);
    },
    logout: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("access_token_expiry");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("refresh_token_expiry");
      localStorage.removeItem("algolia_key");
      state.access_token = null;
      state.access_token_expiry = null;
      state.refresh_token = null;
      state.refresh_token_expiry = null;
      state.algolia_key = null;
      window.location.href = "/login";
    },
	},
});

// Action creators are generated for each case reducer function
export const { setLoading, setError, login, logout } = authSlice.actions;

export default authSlice.reducer;