import ContentLoader from "react-content-loader";
import React from "react";
import { useSelector } from "react-redux";

const BaseLoader = ({ children, viewBox, ...props }) => {
  const mode = useSelector(state => state.theme.style)

  return (
    <ContentLoader 
      speed={2}
      viewBox={viewBox}
      backgroundColor={(mode === "dark")?"rgb(17, 24, 39)":"#e5e7eb"}
      foregroundColor={(mode ==="dark")?"rgb(31, 41, 55)":"#d1d5db"}
      className="block"
      {...props}
    >
      { children }
    </ContentLoader>
  )
}

export default BaseLoader