import React from "react"
import Loader from 'react-loaders'
import { CubeTransparentIcon } from "@heroicons/react/outline"
import { ChevronRightIcon, PlusSmIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import AddGeoreferenceModal from "../../../components/modals/AddGeoreferenceModal";

import { useFindAllGeoreferencingQuery } from "../../../redux/services/api"
import { useTranslation } from "react-i18next";

const GeoreferencingPage = () => {
  const { t } = useTranslation();
  const [openAddGeoreference, setOpenAddGeoreference] = React.useState(false);
  const { data, isLoading, isFetching, isSuccess } = useFindAllGeoreferencingQuery()

  return (
    <React.Fragment>
      <AddGeoreferenceModal open={openAddGeoreference} onClose={() => setOpenAddGeoreference(false)} />
      <div className="flex flex-col flex-grow px-4 my-2">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row">  
            <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 mr-4">
              {t('pages.settings.georeferencing.title')}
            </h1>
          </div>
          <div>
            <button
              onClick={() => setOpenAddGeoreference(true)}
              className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-800 focus:dark:bg-gray-700 hover:dark:bg-gray-700 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
            >
              <PlusSmIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
        {/* Show notifications when loaded */}
        { isSuccess ? (
          // Show notifications if available else show empty icon
          (!isLoading && !isFetching && data.length > 0) ? (
            <div className="flex flex-col gap-4 mt-5">
              {/* Map through each georeference in data */}
              {
                data.map((georeference) => (
                  <Link to={`/settings/georeferencing/${georeference.id}`} key={georeference.id} className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:text-gray-700 hover:dark:text-gray-300 p-3 rounded">
                    <div className="flex flex-row items-center">
                      <h3 className="font-semibold">
                        { georeference.name }
                      </h3>
                    </div>
                    <ChevronRightIcon className="w-6 h-6" />
                  </Link>
                ))
              }
            </div>
          ) : (
            <div className="flex flex-col flex-grow items-center justify-center">
              <div className="flex flex-col justify-center items-center md:-mt-8">
                <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
                <p className="mt-3 text-gray-600 text-center">
                  {t('pages.settings.georeferencing.empty')}
                </p>
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-col flex-grow items-center justify-center">
            <div className="flex flex-col justify-center items-center md:-mt-8">
              <Loader type="line-scale" active={true} />
              <p className="mt-3 text-gray-600 text-center">
                {t('loading')}
              </p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default GeoreferencingPage;