import { BellIcon, CogIcon, SearchIcon, UsersIcon } from '@heroicons/react/solid'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { GlobeAltIcon } from '@heroicons/react/outline'
import { Link, useLocation } from 'react-router-dom'
import { MenuIcon } from '@heroicons/react/outline'
import { isMacOs, isMobile, isWindows } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import BaseLoader from './loaders/BaseLoader'
import ThemeSwitcher from './ThemeSwitcher'
import logo from '../assets/logo.svg'
import { logout } from '../redux/auth'
import { setOpen } from "../redux/sidebar";
import { setSearchOpen } from '../redux/search'
import { useFindAllNotificationsQuery, useGetProfileQuery } from '../redux/services/api'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(state => state.sidebar.open);
  const location = useLocation();

  // Get Profile
  const { data: profile, isLoading: loadingProfile, isFetching: fetchingProfile, isSuccess: isSuccessProfile } = useGetProfileQuery();

  // Get all notifications
  const {
    data: notifications,
    isSuccess: isSuccessNotifications
  } = useFindAllNotificationsQuery();

  // Calculate new notifications if read parameter includes profile id
  const newNotifications = (isSuccessNotifications)?notifications.filter(notification => !notification.read):[];

  // Get language from localStorage
  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    }
  },[]);

  return (
    <Disclosure as="nav" className="shadow-md z-20 bg-white dark:bg-gray-800  transition-colors ease-linear">
      {({ open }) => (
        <>
          <div className="px-5">
            <div className="relative flex items-center justify-between h-20">
              <div className="flex-shrink-0 flex items-center">
                <button className='text-gray-400 dark:text-gray-400 mr-8 p-1 rounded hidden md:block' onClick={() => dispatch(setOpen(!sidebarOpen))}>
                  {
                    sidebarOpen ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28"><path fill="none" d="M0 0H24V24H0z"/><path fill='currentColor' d="M21 18v2H3v-2h18zM6.95 3.55v9.9L2 8.5l4.95-4.95zM21 11v2h-9v-2h9zm0-7v2h-9V4h9z"/></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28"><path fill="none" d="M0 0H24V24H0z"/><path fill='currentColor' d="M21 18v2H3v-2h18zM17.05 3.55L22 8.5l-4.95 4.95v-9.9zM12 11v2H3v-2h9zm0-7v2H3V4h9z"/></svg>
                  }
                </button>
                <Link to="/">
                  <img
                    className="block h-11 w-auto"
                    draggable="false"
                    src={logo}
                    alt="Workflow"
                  />
                </Link>
              </div>

              {/* Menu bar button for mobile displays */}
              <div className="absolute inset-y-0 right-0 flex row gap-6 items-center pr-2 sm:static sm:inset-auto sm:pr-0 md:hidden">
                <button className='text-gray-400 dark:text-gray-500' onClick={() => dispatch(setSearchOpen(true))}>
                  <SearchIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <Disclosure.Button>
                  <MenuIcon className="w-6 h-6 text-gray-400 dark:text-gray-500" />
                </Disclosure.Button>
              </div>

              {/* Menu Bar for large displays */}
              <div className="items-center pr-2 sm:inset-auto sm:pr-0 hidden md:flex md:flex-row flex-grow justify-end">
                {/* Search Button */}
                <button onClick={() => dispatch(setSearchOpen(true))} className="flex flex-row items-center relative p-1 ml-5 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-white focus:outline-none">
                  {
                    !isMobile ?
                      <kbd title='Command + K to Search' className='flex flex-row gap-0.5 mr-3 text-sm bg-gray-200 dark:bg-gray-600 px-2 rounded opacity-80'>
                        <span>
                          {
                            isMacOs ?
                              '⌘'
                            : isWindows ?
                                'Alt'
                              :
                                null
                          }
                          
                        </span>
                        +
                        <span>
                          K
                        </span>
                      </kbd>
                    :
                      null
                  }
                  <SearchIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                
                {/* Employees Button */}
                <Link
                  to="/employee"
                  className="relative p-1 ml-5 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-white focus:outline-none"
                >
                  <span className="sr-only">{ t('navigation.tabs.employees') }</span>
                  <UsersIcon className="h-6 w-6" aria-hidden="true" />
                </Link>

                {/* Notifications */}
                <Link
                  to="/notifications"
                  className="relative p-1 ml-5 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-white focus:outline-none"
                >
                  <span className="sr-only">{ t('navigation.tabs.notifications') }</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  {/* Calculate all new notifications and show badge */}
                  {newNotifications.length > 0 && (
                    <>
                      <span className="absolute right-0 -top-2 mt-1 -mr-1 text-xs font-medium animate-ping motion-safe:animate-ping text-gray-50 dark:text-gray-300 bg-blue-500 dark:bg-blue-600 rounded-full px-2 py-0">
                        <small> { newNotifications.length } </small>
                      </span>
                      <span className="absolute right-0 -top-2 mt-1 -mr-1 z-10 text-xs font-medium text-gray-50 dark:text-gray-300 bg-blue-500 dark:bg-blue-600 rounded-full px-2 py-0">
                        <small> { newNotifications.length } </small>
                      </span>
                    </>
                  )}
                </Link>

                {/* Settings Button */}
                <Link
                  to="/settings"
                  className="relative p-1 ml-5 rounded-full text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-white focus:outline-none"
                >
                  <span className="sr-only">{ t('navigation.tabs.settings') }</span>
                  <CogIcon className="h-6 w-6" aria-hidden="true" />
                </Link>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-6 relative">
                  <div>
                    <Menu.Button className="flex text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-600 font-semibold rounded-full focus:outline-none">
                      {/* Show loader if profile is loading or fetching, else show profile.name */}
                      {
                        (loadingProfile || fetchingProfile) ?
                          <BaseLoader viewBox={"0 0 100 16"} style={{ width: 100, height: 16 }}>
                            {/* Create rect for profile name */}
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="16" />
                          </BaseLoader>
                        :
                          (isSuccessProfile) ? profile.name : 'Account'
                      }
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => dispatch(logout())}
                            className={classNames(active ? 'text-gray-500 dark:text-gray-300' : '', 'block px-4 py-2 text-sm text-gray-400 font-semibold')}
                          >
                            { t('navigation.tabs.signout') }
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 z-20">
              <Link to="/">
                <button
                  className={classNames(
                    (typeof location.pathname === 'string' && location.pathname === '/') ? 'bg-gray-400 dark:bg-gray-900 text-gray-100 dark:text-white' : 'text-gray-400 hover:bg-gray-400 hover:dark:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium text-left w-full'
                  )}
                >
                  { t('navigation.tabs.dashboard') }
                </button>
              </Link>
              <Link to="/employee">
                <button
                  className={classNames(
                    (typeof location.pathname === 'string' && location.pathname.includes('/employee')) ? 'bg-gray-400 dark:bg-gray-900 text-gray-100 dark:text-white' : 'text-gray-400 hover:bg-gray-400 hover:dark:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium text-left w-full mt-2'
                  )}
                >
                  { t('navigation.tabs.employees') }
                </button>
              </Link>
              <Link to="/notifications">
                <button
                  className={classNames(
                    (typeof location.pathname === 'string' && location.pathname.includes('/notifications')) ? 'bg-gray-400 dark:bg-gray-900 text-gray-100 dark:text-white' : 'text-gray-400 hover:bg-gray-400 hover:dark:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium text-left w-full mt-2'
                  )}
                >
                  { t('navigation.tabs.notifications') }
                </button>
              </Link>
              <Link to="/settings">
                <button
                  className={classNames(
                    (typeof location.pathname === 'string' && location.pathname === '/settings') ? 'bg-gray-400 dark:bg-gray-900 text-gray-100 dark:text-white' : 'text-gray-400 hover:bg-gray-400 hover:dark:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium text-left w-full mt-2'
                  )}
                >
                  { t('navigation.tabs.settings') }
                </button>
              </Link>
              <button
                  onClick={() => dispatch(logout())}
                  className={classNames('text-gray-400 hover:bg-gray-400 hover:dark:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-left w-full mt-2')}
                >
                  { t('navigation.tabs.signout') }
              </button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}