import algoliasearch from 'algoliasearch/lite';
import debounce from 'debounce-promise'
import { SearchIcon } from '@heroicons/react/solid';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeviceImage from './DeviceImage';
import { setSearchOpen } from '../redux/search';

const Search = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchBoxRef = useDetectClickOutside({ onTriggered: () => dispatch(setSearchOpen(false)) });
    const algolia_key = useSelector((state) => state.auth.algolia_key);
    const [autocompleteState, setAutocompleteState] = useState({});
    const inputRef = useRef(null);
    const searchClient = useRef(null);
    const algoliaDebounceTimeout = useRef(null);

    const algoliaSource = debounce(({ query }) => [
        // (3) Use an Algolia index source.
        {
            sourceId: 'devices',
            getItemInputValue: ({ item }) => item.friendly_name,
            getItems: () => getAlgoliaResults({
                searchClient: searchClient.current,
                queries: [
                    {
                        indexName: 'rpsmart_default',
                        query,
                        params: {
                            hitsPerPage: 40,
                            highlightPreTag: '<mark>',
                            highlightPostTag: '</mark>',
                        },
                    },
                ],
            }),
            getItemUrl: ({ item }) => item.objectID,
        },
    ], 500);

    const autocomplete = useMemo(() =>
        createAutocomplete({
            onStateChange({ state }) {
                // (2) Synchronize the Autocomplete state with the React state.
                setAutocompleteState(state);
            },
            getSources: algoliaSource
        })
    , [setAutocompleteState, searchClient.current]);

    useEffect(() => {
        searchClient.current = algoliasearch('VZ4OQWUPHP', algolia_key);
    }, [algolia_key]);

    useEffect(() => {
        inputRef.current.focus();
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
            if (algoliaDebounceTimeout.current) {
                clearTimeout(algoliaDebounceTimeout.current);
            }
        }
    }, []);

    return (
        <>
            <div role="button" onKeyDown={({ key }) => key === 'Escape'?dispatch(setSearchOpen(false)):null} className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-start bg-gray-100 dark:bg-gray-900 bg-opacity-20 dark:bg-opacity-40 backdrop-blur-[4px] transition-all cursor-default z-30">
                <div className='flex flex-col max-w-[800px] w-full p-5 my-10 md:mt-24 min-h-0' ref={searchBoxRef}>
                    <div className='flex flex-col bg-white dark:bg-gray-800 p-4 border border-gray-200 dark:border-gray-700 border-opacity-60 w-full rounded-lg shadow-2xl min-h-0'>
                        <div className='flex flex-row items-center gap-4'>
                            {
                                autocompleteState.status === undefined || autocompleteState.status === 'idle' ?
                                    <SearchIcon className='w-6 h-6 text-gray-300 dark:text-gray-400' />
                                :
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={26} height={26} className='animate-spin text-blue-500'><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
                            }
                            <div className='flex flex-row grow'>
                                <form {...autocomplete.getFormProps({ inputElement: inputRef.current })} className="w-full">
                                    <input ref={inputRef} autoFocus={true} {...autocomplete.getInputProps({})} type='text' className='w-full h-full border-0 bg-transparent dark:caret-gray-400 text-gray-700 dark:text-gray-400 text-lg focus:outline-none' placeholder='Search Here' />
                                </form>
                            </div>
                            <button onClick={() => dispatch(setSearchOpen(false))} className='px-2 py-1 font-semibold text-white dark:text-gray-400 text-xs text-center bg-gray-300 dark:bg-gray-600 rounded'>
                                ESC
                            </button>
                        </div>
                        {
                            autocompleteState.isOpen ?
                                <div {...autocomplete.getPanelProps({})} className='flex flex-col w-full mt-2 border-t border-gray-200 dark:border-gray-700 overflow-y-auto'>
                                    {
                                        autocompleteState.collections.map(({ source, items }, index) => {
                                            return (
                                                <div className='mt-5' key={`source-${index}`}>
                                                    {items.length > 0 && (
                                                    <ul {...autocomplete.getListProps()}>
                                                        {items.map((item) => (
                                                        <li
                                                            key={item.objectID}
                                                            role="button"
                                                            onClick={() => { navigate(`/device/${item.objectID}`); dispatch(setSearchOpen(false))}}
                                                            className="flex flex-row items-center gap-5 py-3 px-5 mt-3 bg-gray-100 hover:bg-green dark:bg-gray-700 dark:hover:bg-green hover:text-white hover:bg-opacity-90 dark:hover:bg-opacity-90 text-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer rounded-lg"
                                                        >
                                                            <div>
                                                                {
                                                                    item.product_category === 'mose' ?
                                                                        <DeviceImage model={item.product_model} width={32} height={32} />
                                                                    :
                                                                        '?'
                                                                }
                                                            </div>
                                                            <div className='flex flex-col gap-0.5'>
                                                                <span className='font-semibold' dangerouslySetInnerHTML={{__html: item._highlightResult.friendly_name.value}} />
                                                                {
                                                                    item.location ?
                                                                        <span className='text-sm' dangerouslySetInnerHTML={{__html: item._highlightResult.location.value}} />
                                                                    :
                                                                        null
                                                                }
                                                                <div className='flex flex-row gap-1 mt-1'>
                                                                    <span className='text-[10px] px-2 py-0.5 rounded-full bg-gray-600' dangerouslySetInnerHTML={{__html: item._highlightResult.serial_number.value}}/>
                                                                    {
                                                                        item.mac_address ?
                                                                            <span className='text-[10px] px-2 py-0.5 rounded-full bg-gray-600' dangerouslySetInnerHTML={{__html: item._highlightResult.mac_address.value}}/>
                                                                        :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                    )}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search;