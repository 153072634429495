import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
	name: "theme",
	initialState: {
		style: "dark"
	},
	reducers: {
		// Load theme from localstorage
		loadTheme: (state, action) => {
			const theme = localStorage.getItem("theme");
			if (theme) {
				state.style = theme;
			}
			// Get HTML element and change class
			const html = document.getElementsByTagName("html")[0];
			html.classList.remove("light", "dark");
			html.classList.add(state.style);
		},
		// Set theme
		setStyle: (state, action) => {
			state.style = action.payload;
			// Set the theme in local storage
			localStorage.setItem("theme", action.payload);
			// Get HTML element and change class
			const html = document.getElementsByTagName("html")[0];
			html.classList.remove("light", "dark");
			html.classList.add(state.style);
		}
	},
});

// Action creators are generated for each case reducer function
export const { loadTheme, setStyle } = themeSlice.actions;

export default themeSlice.reducer;