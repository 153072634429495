import React from "react";
import { Dialog, Transition } from '@headlessui/react'
import { Formik, Form, Field, FieldArray, ErrorMessage as ErrorMessageFormik } from 'formik';
import InputWithLabel from '../formik/InputWithLabel'
import { PlusSmIcon, XIcon } from "@heroicons/react/solid";
import { CubeTransparentIcon } from "@heroicons/react/outline"
import { toast } from 'react-toastify';

import { useUpdateGeoreferencingMutation } from "../../redux/services/api";
import { useTranslation } from "react-i18next";

const EditGeoreferenceCoordinatesModal = ({ onClose, value = "", georeferenceID, open }) => {
  const { t } = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [updateGeoreference, {
    isLoading: mutationLoading,
    isSuccess: Success,
    isError,
    error: ErrorMessage,
    reset
  }] = useUpdateGeoreferencingMutation();

  const isLoading = mutationLoading || Loading;

  const handleSubmit = async (coordinates) => {
    setLoading(true);
    if (reset) {
      reset();
    }
    await updateGeoreference({
      id: georeferenceID,
      coordinates,
    });
    setLoading(false);
  }

  const handleClose = () => {
    if (!isLoading)
      onClose();
  }

  React.useEffect(() => {
    if (isError && ErrorMessage) {
      toast.error(ErrorMessage.message);
      setLoading(false);
    }
  }, [isError, ErrorMessage]);

  React.useEffect(() => {
    if (Success) {
      onClose();
      toast.success(t('modals.edit_georeference_coordinates.toast_success'));
    }
  }, [Success]);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-90" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-200 dark:bg-gray-800 shadow-xl rounded-lg">
              <Formik
                handleBlur={() => {
                  if (reset) {
                    reset();
                  }
                }}
                initialValues={{
                  coordinates: value
                }}
                onSubmit={async (values) => {
                  await handleSubmit(values.coordinates)
                }}
                enableReinitialize={true}
              >
                {({ touched, errors, isSubmitting, values }) =>
                  <Form className="grid grid-cols-1 grid-flow-rows w-full">
                    <FieldArray name="coordinates">
                      {({ insert, remove, push }) =>
                        <>
                          <Dialog.Title
                            as="div"
                            className="flex flex-row justify-between items-center"
                          >
                            <span className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                              {t('modals.edit_georeference_coordinates.title')}
                            </span>
                            <button
                              type="button"
                              onClick={() => { if (values.coordinates.length < 4) { insert(values.coordinates.length, { lat: null, lng: null }) } }}
                              className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-700 focus:dark:bg-gray-600 hover:dark:bg-gray-600 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
                            >
                              <PlusSmIcon className="w-4 h-4" />
                            </button>
                          </Dialog.Title>
                          <div className="my-2 text-sm text-gray-400 dark:text-gray-600">
                            {t('modals.edit_georeference_coordinates.subtitle')}
                          </div>
                          <div className="flex flex-col overflow-y-scroll gap-1 soft-scrollbar scroll-smooth pr-3 my-2" style={{ maxHeight: 200 }}> 
                            {/* Show empty icon if values.coordinates  */}
                            {values.coordinates.length === 0 &&
                              <div className="flex flex-col flex-grow justify-center items-center dark:text-gray-500 text-xs my-4">
                                <CubeTransparentIcon className="w-10 h-10" />
                                <span className="mt-4">
                                  {t('modals.edit_georeference_coordinates.tip')}
                                </span>
                              </div>
                            }
                            {/* Create block with lat, lng and x button (remove) on right side */}
                            {/* Map coordinates */}
                            {values.coordinates && values.coordinates.map((coordinate, index) => (
                              <div className="flex flex-col gap-4 mt-2" key={index}>
                                <div className="w-full flex flex-row items-center bg-gray-700 rounded-lg p-3">
                                  <div className="flex flex-row flex-grow items-center">
                                    <div className="mr-3">
                                      <Field
                                        type="number"
                                        step="0.000000001"
                                        min="-90"
                                        max="90"
                                        name={`coordinates.${index}.lat`}
                                        autoComplete="off"
                                        placeholder="lat"
                                        required
                                        className={`px-4 py-1 focus:ring-green-500 border-gray-200 dark:border-gray-900 focus:border-gray-400 dark:bg-gray-900 disabled:opacity-60 dark:text-gray-300 block sm:text-sm border-2  focus:outline-none rounded-md w-full`}
                                      />
                                    </div>
                                    <div className="mr-3">
                                      <Field
                                        type="number"
                                        name={`coordinates.${index}.lng`}
                                        step="0.000000001"
                                        min="-180"
                                        max="180"
                                        autoComplete="off"
                                        placeholder="lng"
                                        required
                                        className={`px-4 py-1 focus:ring-green-500 border-gray-200 dark:border-gray-900 focus:border-gray-400 dark:bg-gray-900 disabled:opacity-60 dark:text-gray-300 block sm:text-sm border-2  focus:outline-none rounded-md w-full`}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="flex flex-row items-center justify-center p-1 text-xs font-semibold rounded-full text-gray-400 bg-gray-200 dark:bg-gray-700 focus:dark:bg-gray-600 hover:dark:bg-gray-600 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
                                      onClick={() => remove(index)}
                                    >
                                      <XIcon className="w-4 h-4" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      }
                    </FieldArray>
                    <div className="flex flex-row justify-between items-center mt-6">
                      {/* Create cancel button */}
                      <button
                        type="button"
                        className=" bg-gray-500 hover:bg-gray-700 text-sm disabled:opacity-40 disabled:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                        onClick={handleClose}
                      >
                        {t('modals.edit_georeference_coordinates.cancel_action')}
                      </button>
                      {/* Create custom tailwind button */}
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 disabled:opacity-40 disabled:bg-blue-500 text-sm text-white font-semibold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                      >
                        {/* If loading, show svg spinner */}
                        {isLoading ? (
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        ) : (
                          t('modals.edit_georeference_coordinates.submit_action')
                        )}
                      </button>
                    </div>
                  </Form>
                }
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default EditGeoreferenceCoordinatesModal;