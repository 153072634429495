import Loader from 'react-loaders'
import { ChevronRightIcon, PencilAltIcon } from "@heroicons/react/solid";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";

import AssignmentModal from "../components/modals/AssignmentModal";
import DeleteGroupModal from "../components/modals/DeleteGroupModal";
import EditGroupNameModal from "../components/modals/EditGroupNameModal";
import { setDevices, setFocus } from "../redux/map";
import {
    useAssignDevicesToGroupMutation,
    useAssignEmployeesToGroupMutation,
    useFindAllDevicesQuery,
    useFindAllEmployeesQuery,
    useGetGroupQuery,
    useUnassignDevicesFromGroupMutation,
    useUnassignEmployeesFromGroupMutation,
} from "../redux/services/api";

const GroupDevicesModal = ({ open, onClose, group, devices, loading }) => {
    const { t } = useTranslation();
    const clearHandle = useRef(null);

    const [
        assignDevicesToGroup,
        {
            isLoading: loadingAssignDevicesToGroup,
            isSuccess: successAssignDevicesToGroup,
            isError: errorAssignDevicesToGroup,
            reset: resetAssignDevicesToGroup,
        }
    ] = useAssignDevicesToGroupMutation();

    const [
        unassignDevicesFromGroup,
        {
            isLoading: loadingUnassignDevicesFromGroup,
            isSuccess: successUnassignDevicesFromGroup,
            isError: errorUnassignDevicesFromGroup,
            reset: resetUnassignDevicesFromGroup,
        }
    ] = useUnassignDevicesFromGroupMutation();
    
    useEffect(() => {
        if (successAssignDevicesToGroup || successUnassignDevicesFromGroup) {
            toast.success(successAssignDevicesToGroup ? t('modals.group_devices.toast_assign_success') : t('modals.group_devices.toast_unassign_success'));
            resetAssignDevicesToGroup();
            resetUnassignDevicesFromGroup();
            clearHandle.current();
        }
    }, [successAssignDevicesToGroup, successUnassignDevicesFromGroup]);

    useEffect(() => {
        if (errorAssignDevicesToGroup || errorUnassignDevicesFromGroup) {
            toast.error(errorAssignDevicesToGroup ? t('modals.group_devices.toast_assign_error') : t('modals.group_devices.toast_unassign_error'));
            resetAssignDevicesToGroup();
            resetUnassignDevicesFromGroup();
        }
    }, [errorAssignDevicesToGroup, errorUnassignDevicesFromGroup])
    
    
    return (
        <AssignmentModal
            open={open}
            loading={loadingAssignDevicesToGroup || loadingUnassignDevicesFromGroup || loading}
            onClose={onClose}
            onAssign={(deviceIDs) => assignDevicesToGroup({ groupID: group?.id, deviceIDs })}
            onUnassign={(deviceIDs) => unassignDevicesFromGroup({ groupID: group?.id, deviceIDs })}
            clear={clearHandle}
            data={devices}
            dataType={'device'}
            entityID={group?.id}
            entityType={'group'}
            entityAttribute={'groups'}
            title={t('modals.group_devices.title')}
            assignButtonLabel={t('modals.group_devices.assign_button_label')}
            unassignButtonLabel={t('modals.group_devices.unassign_button_label')}
        />
    )
}

const GroupEmployeesModal = ({ open, onClose, group, employees, loading }) => {
    const { t } = useTranslation();
    const clearHandle = useRef(null);

    const [
        assignEmployeesToGroup,
        {
            isLoading: loadingAssignEmployeesToGroup,
            isSuccess: successAssignEmployeesToGroup,
            isError: errorAssignEmployeesToGroup,
            reset: resetAssignEmployeesToGroup,
        }
    ] = useAssignEmployeesToGroupMutation();

    const [
        unassignEmployeesFromGroup,
        {
            isLoading: loadingUnassignEmployeesFromGroup,
            isSuccess: successUnassignEmployeesFromGroup,
            isError: errorUnassignEmployeesFromGroup,
            reset: resetUnassignEmployeesFromGroup,
        }
    ] = useUnassignEmployeesFromGroupMutation();

    useEffect(() => {
        if (successAssignEmployeesToGroup || successUnassignEmployeesFromGroup) {
            toast.success(successAssignEmployeesToGroup ? t('modals.group_employees.toast_assign_success') : t('modals.group_employees.toast_unassign_success'));
            resetAssignEmployeesToGroup();
            resetUnassignEmployeesFromGroup();
            clearHandle.current();
        }
    }, [successAssignEmployeesToGroup, successUnassignEmployeesFromGroup]);

    useEffect(() => {
        if (errorAssignEmployeesToGroup || errorUnassignEmployeesFromGroup) {
            toast.error(errorAssignEmployeesToGroup ? t('modals.group_employees.toast_assign_error') : t('modals.group_employees.toast_unassign_error'));
            resetAssignEmployeesToGroup();
            resetUnassignEmployeesFromGroup();
        }
    }, [errorAssignEmployeesToGroup, errorUnassignEmployeesFromGroup]);

    return (
        <AssignmentModal
            open={open}
            loading={loadingAssignEmployeesToGroup || loadingUnassignEmployeesFromGroup || loading}
            onClose={onClose}
            onAssign={(employeeIDs) => assignEmployeesToGroup({ groupID: group?.id, employeeIDs })}
            onUnassign={(employeeIDs) => unassignEmployeesFromGroup({ groupID: group?.id, employeeIDs })}
            clear={clearHandle}
            data={employees}
            dataType={'employee'}
            entityID={group?.id}
            entityType={'group'}
            entityAttribute={'groups'}
            title={t('modals.group_employees.title')}
            assignButtonLabel={t('modals.group_employees.assign_button_label')}
            unassignButtonLabel={t('modals.group_employees.unassign_button_label')}
        />
    )
}


const GroupSettingsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupID } = useParams();

    const [openEditNameModal, setOpenEditNameModal] = useState(false);
    const [openGroupEmployeesModal, setOpenGroupEmployeesModal] = useState(false);
    const [openGroupDevicesModal, setOpenGroupDevicesModal] = useState(false);
    const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);

    const {
        data: group,
        isLoading: loadingGroup,
        isFetching: fetchingGroup,
    } = useGetGroupQuery(groupID);

    const {
        data: devices,
        isLoading: loadingDevices,
        isFetching: fetchingDevices,
    } = useFindAllDevicesQuery();

    const { 
        data: employees,
        isLoading: loadingEmployees,
        isFetching: fetchingEmployees,
    } = useFindAllEmployeesQuery();

    const groupDevices = useMemo(() => {
        if (loadingDevices || !devices) return [];
        return devices.filter((d) => {
            if (d?.groups || Array.isArray(d.groups)) {
                return d.groups.includes(groupID);
            } else {
                return false;
            }
        })
    }, [devices, loadingDevices]);

    const handleOpenEditNameModal = useCallback(() => {
        setOpenEditNameModal(true);
    }, [setOpenEditNameModal]);

    const handleCloseEditNameModal = useCallback(() => {
        setOpenEditNameModal(false);
    }, [setOpenEditNameModal]);

    const handleOpenGroupEmployeesModal = useCallback(() => {
        setOpenGroupEmployeesModal(true);
    }, [setOpenGroupEmployeesModal]);

    const handleCloseGroupEmployeesModal = useCallback(() => {
        setOpenGroupEmployeesModal(false);
    }, [setOpenGroupEmployeesModal]);

    const handleOpenGroupDevicesModal = useCallback(() => {
        setOpenGroupDevicesModal(true);
    }, [setOpenGroupDevicesModal]);

    const handleCloseGroupDevicesModal = useCallback(() => {
        setOpenGroupDevicesModal(false);
    }, [setOpenGroupDevicesModal]);

    const handleOpenDeleteGroupModal = useCallback(() => {
        setOpenDeleteGroupModal(true);
    }, [setOpenDeleteGroupModal]);

    const handleCloseDeleteGroupModal = useCallback(() => {
        setOpenDeleteGroupModal(false);
    }, [setOpenDeleteGroupModal]);

    useEffect(() => {
        if (!devices) return;
        const serializedDevices = groupDevices.map(d => d.id);
        dispatch(setDevices(serializedDevices));
        dispatch(setFocus(serializedDevices));
    }, [groupDevices]);

    return (
        <div className="flex flex-col grow mx-4 mb-14">
            <GroupDevicesModal open={openGroupDevicesModal} onClose={handleCloseGroupDevicesModal} group={group} devices={devices} loading={loadingGroup || fetchingGroup || loadingDevices || fetchingDevices} />
            <GroupEmployeesModal open={openGroupEmployeesModal} onClose={handleCloseGroupEmployeesModal} group={group} employees={employees} loading={loadingGroup || fetchingGroup || loadingEmployees || fetchingEmployees} />
            <EditGroupNameModal open={openEditNameModal} onClose={handleCloseEditNameModal} value={group?.name} groupID={group?.id} />
            <DeleteGroupModal open={openDeleteGroupModal} onClose={handleCloseDeleteGroupModal} groupID={group?.id} />
            {
                !loadingGroup &&
                <div className='flex flex-col grow-0 mt-2'>
                    <h6 className="text-sm text-gray-500 font-semibold">
                        { group?.name}
                    </h6>
                    <h2 className="text-3xl font-semibold mt-1 text-gray-700 dark:text-gray-200">
                        {t('pages.group_settings.title')}
                    </h2>
                </div>
            }
            {
                !loadingGroup &&
                <div className="flex flex-col gap-3 mt-4">
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.group_settings.general.title')}
                        </span>
                    </div>
                    <button onClick={handleOpenEditNameModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                            {t('pages.group_settings.general.settings.name')}
                        </span>
                        <div className="flex flex-row justify-center">
                            <span>
                                { group?.name }
                            </span>
                            <PencilAltIcon className="h-5 w-5 text-gray-600 ml-2" />
                        </div>
                    </button>
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.group_settings.management.title')}
                        </span>
                    </div>
                    <button onClick={handleOpenGroupEmployeesModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                            {t('pages.group_settings.management.settings.employees')}
                        </span>
                        <div className="flex flex-row items-center">
                            <span>
                                { group?.employees.length }
                            </span>
                            <ChevronRightIcon className="h-6 w-6 text-gray-600 ml-2" />
                        </div>
                    </button>
                    <button onClick={handleOpenGroupDevicesModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                            {t('pages.group_settings.management.settings.devices')}
                        </span>
                        <div className="flex flex-row items-center">
                            <span>
                                { group?.devices.length || groupDevices.length }
                            </span>
                            <ChevronRightIcon className="h-6 w-6 text-gray-600 ml-2" />
                        </div>
                    </button>
                    <button onClick={handleOpenDeleteGroupModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer mt-4">
                        <span className="text-red-500">
                            {t('pages.group_settings.delete_action')}
                        </span>
                    </button>
                </div>
            }
            {
                loadingGroup &&
                <div className="flex flex-col flex-grow items-center justify-center h-full">
                    <div className="flex flex-col justify-center items-center">
                        <Loader type="line-scale" active={true} />
                        <p className="mt-3 text-gray-600 text-center">
                            {t('loading')}
                        </p>
                    </div>
                </div>
            }
        </div>
    )
};

export default GroupSettingsPage;