import "react-modern-calendar-datepicker/lib/DatePicker.css";

import React, { useEffect, useState } from 'react';
import { Calendar } from "react-modern-calendar-datepicker";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Popover } from '@headlessui/react'
import { addDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setFrom, setTo } from '../redux/historybar';

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const HistoryBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const show = useSelector(state => state.overviewbar.show)
  const defaultFrom = useSelector(state => state.historybar.from);
  const defaultTo = useSelector(state => state.historybar.to);

  const [ArrowsDisabled, setArrowsDisabled] = useState(false);
  const [state, setState] = useState({
    from: {
      // Set today
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    to: {
      // Set today
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    }
  });
  const debouncedState = useDebounce(state, 500);

  // Calculate date minus one year
  const minimumDate = addDays(new Date(), -365);

  // Set Default state from redux
  useEffect(() => {
    // Get from or set null
    let from, to;
    if (defaultFrom) {
      from = {
        day: new Date(defaultFrom).getDate(),
        month: new Date(defaultFrom).getMonth() + 1,
        year: new Date(defaultFrom).getFullYear(),
      };
    } else {
      from = null;
    }

    // Get to or set null
    if (defaultTo) {
      to = {
        day: new Date(defaultTo).getDate(),
        month: new Date(defaultTo).getMonth() + 1,
        year: new Date(defaultTo).getFullYear(),
      };
    } else {
      to = null;
    }

    // If from or to is null, set to default
    if (!from) {
      // Set start of today
      from = {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      };
    }

    if (!to) {
      // Set end of today
      to = {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      };
    }

    setState({
      from,
      to
    });
  }, []);

  // Check if state.from and state.to are equal, if yes, enable arrows
  useEffect(() => {
    // If state.from and state.to are not null
    if (state.from && state.to) {
      if (state.from.day === state.to.day && state.from.month === state.to.month && state.from.year === state.to.year) {
        setArrowsDisabled(false);
      } else {
        setArrowsDisabled(true);
      }
    } else {
      setArrowsDisabled(true);
    }
  }, [state.from, state.to]);

  // Set Redux History state
  useEffect(() => {
    // Check if debouncedState.from and debouncedState.to are not null
    if (debouncedState.from && debouncedState.to) {
      // Convert debouncedState.from to Date object with time set to 00:00:00
      let from = new Date(debouncedState.from.year, debouncedState.from.month - 1, debouncedState.from.day, 0, 0, 0);
      // Convert debouncedState.to to Date object with time set to 23:59:59
      let to = new Date(debouncedState.to.year, debouncedState.to.month - 1, debouncedState.to.day, 23, 59, 59);
      dispatch(setFrom(from.toJSON()));
      dispatch(setTo(to.toJSON()));
    }
  }, [debouncedState.from, debouncedState.to, dispatch]);

  // Get Today
  const getToday = () => {
    const today = new Date();
    return {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    }
  };
  
  // Function for subtracting one day from state
  const subtractDay = () => {
    // If state.from is null, subtract one day from default date
    if (state.from !== null && state.to !== null) {
      // If state.from is not null, subtract one day from state.from
      const newDate = {
        day: state.from.day - 1,
        month: state.from.month,
        year: state.from.year
      }
      // If day is less than 1, set day to 31 and subtract month by 1
      if (newDate.day < 1) {
        newDate.day = 31;
        newDate.month = newDate.month - 1;
      }
      // If month is less than 1, set month to 12 and subtract year by 1
      if (newDate.month < 1) {
        newDate.month = 12;
        newDate.year = newDate.year - 1;
      }

      // Make sure date is not less than minimum date
      if (new Date(newDate.year, newDate.month - 1, newDate.day) < minimumDate) {
        newDate.day = new Date(minimumDate).getDate();
        newDate.month = new Date(minimumDate).getMonth() + 1;
        newDate.year = new Date(minimumDate).getFullYear();
      }

      setState({
        from: newDate,
        to: newDate
      });
    }
  };

  // Add Day
  const addDay = () => {
    // If state.to is null, add one day to current date
    if (state.to !== null && state.from !== null) {
      // If state.to is not null, add one day to state.to
      const newDate = {
        day: state.to.day + 1,
        month: state.to.month,
        year: state.to.year
      }
      // If day is greater than 31, set day to 1 and add month by 1
      if (newDate.day > 31) {
        newDate.day = 1;
        newDate.month = newDate.month + 1;
      }
      // If month is greater than 12, set month to 1 and add year by 1
      if (newDate.month > 12) {
        newDate.month = 1;
        newDate.year = newDate.year + 1;
      }

      // Make sure date is not greater than current date
      if (new Date(newDate.year, newDate.month - 1, newDate.day) > new Date()) {
        newDate.day = new Date().getDate();
        newDate.month = new Date().getMonth() + 1;
        newDate.year = new Date().getFullYear();
      }
      
      setState({
        from: newDate,
        to: newDate
      });
    }
  };

  // Set Today
  const setToday = () => {
    // Set state from new date
    setState({
      from: {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      to: {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    });
  };

  // Set date range to current week
  const setCurrentWeek = () => {
    // Get starting date of current week in date object
    const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay());
    // Get ending date of current week in date object
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() + 6);
    // Set state.from to starting date
    setState({
      from: {
        day: startDate.getDate(),
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear()
      },
      to: {
        day: endDate.getDate(),
        month: endDate.getMonth() + 1,
        year: endDate.getFullYear()
      }
    });
  };

  // Set date range to current month
  const setCurrentMonth = () => {
    // Set state.from to first day of current month and state.to to last day of current month
    setState({
      from: {
        day: 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      to: {
        day: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    });
  };

  // Set date range to current year till end
  const setCurrentYear = () => {
    setState({
      from: {
        day: 1,
        month: 1,
        year: new Date().getFullYear()
      },
      to: {
        day: new Date(new Date().getFullYear(), 11, 31).getDate(),
        month: 12,
        year: new Date().getFullYear()
      }
    });
  };


  return (
    show && (
    <div className='flex flex-col justify-center md:flex-row md:justify-between items-center w-full p-4'>
      <div className='flex flex-row items-center mb-4 md:mb-0'>
        <ChevronLeftIcon className={`w-6 h-6 text-gray-300 dark:text-gray-600 select-none ${((ArrowsDisabled) ? 'opacity-20': 'cursor-pointer')}`} onClick={() => (ArrowsDisabled)?null:subtractDay()} />
        <Popover className="relative px-4">
          <Popover.Button>
            {/* Check if state.from and state.to is not null */}
            {state.from && state.to ? 
              // Check if state.from and state.to is equal
              (state.from.day === state.to.day && state.from.month === state.to.month && state.from.year === state.to.year) ?
                // If equal to default date, show "Today"
                (state.from.day === getToday().day && state.from.month === getToday().month && state.from.year === getToday().year) ?
                  t('components.historybar.today')
                :
                  // If it's yestarday, then display "Yesterday"
                  (state.from.day === getToday().day - 1 && state.from.month === getToday().month && state.from.year === getToday().year) ?
                    t('components.historybar.yesterday')
                  :
                    // If not equal to default date, show date ( use ordinal date and show month name )
                    `${state.from.day}${nth(state.from.day)} ${new Date(state.from.year, state.from.month - 1, state.from.day).toLocaleString('default', { month: 'long' })} ${state.from.year}`
              :
                // If state.from and state.to is a range, show date range
                `${state.from.day}/${state.from.month}/${state.from.year} - ${state.to.day}/${state.to.month}/${state.to.year}`
              :
                // If state.from and state.to is null, show "Select Date"
                t('components.historybar.select_date')
            }
          </Popover.Button>

          <Popover.Panel className="absolute z-20 rounded-lg overflow-hidden">    
            <Calendar
              value={state}
              onChange={s => setState(s)}
              minimumDate={{
                year: minimumDate.getFullYear(),
                month: minimumDate.getMonth(),
                day: minimumDate.getDate()
              }}
              shouldHighlightWeekends
            />
          </Popover.Panel>
        </Popover>
        <ChevronRightIcon className={`w-6 h-6 text-gray-300 dark:text-gray-600 select-none ${((ArrowsDisabled) ? 'opacity-20': 'cursor-pointer')}`} onClick={() => (ArrowsDisabled)?null:addDay()} />
      </div>
      {/* Flex row of mini buttons */}
      <div className='flex flex-row flex-grow-0'>
        <button onClick={setToday} className='flex flex-row items-center justify-center p-1 px-3 text-xs font-semibold rounded-full text-gray-500 dark:bg-gray-800 hover:dark:bg-gray-700 focus:dark:bg-gray-700 mr-2 dark:text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:shadow-outline transition-colors duration-150'>
          {t('components.historybar.today')}
        </button>
        <button onClick={setCurrentWeek} className='flex flex-row items-center justify-center p-1 px-3 text-xs font-semibold rounded-full text-gray-500 dark:bg-gray-800 hover:dark:bg-gray-700 focus:dark:bg-gray-700 mr-2 dark:text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:shadow-outline transition-colors duration-150'>
          {t('components.historybar.this_week')}
        </button>
        <button onClick={setCurrentMonth} className='flex flex-row items-center justify-center p-1 px-3 text-xs font-semibold rounded-full text-gray-500 dark:bg-gray-800 hover:dark:bg-gray-700 focus:dark:bg-gray-700 mr-2 dark:text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:shadow-outline transition-colors duration-150'>
          {/* Get current month name */}
          {new Date().toLocaleString('default', { month: 'long' })}
        </button>
        <button onClick={setCurrentYear} className={`'flex flex-row items-center justify-center p-1 px-3 text-xs font-semibold rounded-full text-gray-500 dark:bg-gray-800 hover:dark:bg-gray-700 focus:dark:bg-gray-700 dark:text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:shadow-outline transition-colors duration-150`}>
          {/* Get current full year */}
          {new Date().getFullYear()}
        </button>
      </div>
    </div>
    )
  );
}

export default HistoryBar;