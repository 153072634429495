import AursundImage from "../assets/aursund.png"
import KonsisImage from "../assets/konsis.png"
import NobelImage from "../assets/nobel.png"

const DeviceImage = ({ model = null, width = 90 }) => {
    if (model === "aursund_75l" || model === "aursund_100l") {
      return <img src={AursundImage} draggable="false" width={width} height="100%" alt="" />
    } else if (model === "nobel_75l" || model === "nobel_100l") {
      return <img src={NobelImage} draggable="false" width={width} height="100%" alt="" />
    } else if (model === "konsis_75l" || model === "konsis_100l") {
      return <img src={KonsisImage} draggable="false" width={width} height="100%" alt="" />
    } else {
      return <img src={KonsisImage} draggable="false" width={width} height="100%" alt="" />
    }
}


export default DeviceImage;