import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import BaseLoader from './loaders/BaseLoader';

const OverviewBar = () => {
  const { t } = useTranslation();
  const loading = useSelector(state => state.overviewbar.loading)
  const show = useSelector(state => state.overviewbar.show)
  const compressions = useSelector(state => state.overviewbar.compressions)
  const compression_factor = useSelector(state => state.overviewbar.compression_factor)
  const average_fill_level = useSelector(state => state.overviewbar.average_fill_level)
  const times_emptied = useSelector(state => state.overviewbar.times_emptied)

  return (
    <>
      { (show) ? (
        <div className="flex flex-row flex-grow">
          {/* Create flexbox row statistics */}
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row flex-grow justify-between items-center">
              {
                compressions !== null ? (
                  <div className="flex flex-col flex-grow p-4" style={{ minWidth: 160 }}>
                    <div className="flex flex-col flex-grow justify-between items-center">
                      <span className="text-sm text-gray-500 dark:text-gray-300 font-semibold whitespace-nowrap">
                        { t('components.overviewbar.compressions') }
                      </span>
                      {/* Show spinner while loading */}
                      {loading ? (
                        <div className="mt-4" style={{ width: 100, height: 20 }}>
                          <BaseLoader viewBox={'0 0 100 20'}>
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
                          </BaseLoader>
                        </div>
                      ) : (
                        <span className="text-3xl text-gray-400 dark:text-gray-500 font-semibold pt-2">
                          { compressions }
                        </span>
                      )}
                    </div>
                  </div>
                )
                :
                null
              }
              
              {
                compression_factor !== null ? ( 
                  <div className="flex flex-col flex-grow p-4" style={{ minWidth: 160 }}>
                    <div className="flex flex-col flex-grow justify-between items-center">
                      <span className="text-sm text-gray-500 dark:text-gray-300 font-semibold whitespace-nowrap">
                        { t('components.overviewbar.compression_factor') }
                      </span>
                      {/* Show spinner while loading */}
                      {loading ? (
                        <div className="mt-4" style={{ width: 100, height: 20 }}>
                          <BaseLoader viewBox={'0 0 100 20'}>
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
                          </BaseLoader>
                        </div>
                      ) : (
                        <span className="text-3xl text-gray-400 dark:text-gray-500 font-semibold pt-2">
                          { compression_factor }
                        </span>
                      )}
                    </div>
                  </div>
                )
                :
                null
              }
              {/* Average Fill Level */}
              <div className="flex flex-col flex-grow p-4" style={{ minWidth: 160 }}>
                <div className="flex flex-col flex-grow justify-between items-center">
                  <span className="text-sm text-gray-500 dark:text-gray-300 font-semibold whitespace-nowrap">
                    { t('components.overviewbar.avg_fill_level') }
                  </span>
                  {/* Show spinner while loading */}
                  {loading ? (
                    <div className="mt-4" style={{ width: 100, height: 20 }}>
                      <BaseLoader viewBox={'0 0 100 20'}>
                        <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
                      </BaseLoader>
                    </div>
                  ) : (
                    <span className="text-3xl text-gray-400 dark:text-gray-500 font-semibold pt-2">
                      { average_fill_level }%
                    </span>
                  )}
                </div>
              </div>
              {/* Times Emptied */}
              <div className="flex flex-col flex-grow p-4" style={{ minWidth: 160 }}>
                <div className="flex flex-col flex-grow justify-between items-center">
                  <span className="text-sm text-gray-500 dark:text-gray-300 font-semibold whitespace-nowrap">
                    { t('components.overviewbar.times_emptied') }
                  </span>
                  {/* Show spinner while loading */}
                  {loading ? (
                    <div className="mt-4" style={{ width: 100, height: 20 }}>
                      <BaseLoader viewBox={'0 0 100 20'}>
                        <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
                      </BaseLoader>
                    </div>
                  ) : (
                    <span className="text-3xl text-gray-400 dark:text-gray-500 font-semibold pt-2">
                      { times_emptied }
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        ) : null
      }
    </>
  );
};

export default OverviewBar;