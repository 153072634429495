import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query";

import { login, logout } from "../redux/auth";

export const BASE_URL = 'https://portal.rorosprodukter.no/api';

export const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = getState().auth.access_token;
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		return headers;
	}
});

export const baseQueryWithReauth = retry(async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result.error) {
		if (result.error.status === 401) {
			const state = api.getState();
			const { refresh_token, refresh_token_expiry } = state.auth;
			// Check if refresh token has expired
			if (new Date(refresh_token_expiry).getTime() < new Date().getTime()) {
				console.log("Refresh token expired");
				api.dispatch(logout());
				return retry.fail({
					message: "Auth expired"
				});
			}

			// Try to get a new token
			const refreshResult = await fetch(`${BASE_URL}/auth/organization/refresh`, {
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					token: refresh_token
				})
			});

			// Decode JSON Response
			if (refreshResult.status >= 200 && refreshResult.status <= 299) {
				const msg = await refreshResult.json();
				const { access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key } = msg;
				// store the new token
				await api.dispatch(login({ access_token, access_token_expiry, refresh_token, refresh_token_expiry, algolia_key }));
				// retry the initial query
				result = await baseQuery(args, api, extraOptions);
			} else {
				console.log("Invalid refresh token response: ", refreshResult.status);
				api.dispatch(logout());
				return retry.fail({
					message: "Invalid refresh token response"
				});
			}
		} else {
			throw new Error(result.error);
		}
	}
	return result;
}, {
	maxRetries: 10
});
