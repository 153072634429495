import React, { useState, useEffect } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import ReactPaginate from 'react-paginate';

import NotificationTile from "./NotificationTile";

const NotificationsList = ({ items, itemsPerPage }) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [items, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="flex flex-col gap-4">
      {currentItems &&
        currentItems.map((item) => (
          <NotificationTile key={item.id} notification={item} />
        ))
      }
      <ReactPaginate
        breakLabel="..."
        previousLabel={
          <ChevronLeftIcon className="w-5 h-5" />
        }
        nextLabel={
          <ChevronRightIcon className="w-5 h-5" />
        }
        className="px-4 py-3 flex justify-center items-center sm:px-6"
        previousClassName="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-500 hover:bg-gray-50 rounded-l relative inline-flex items-center px-2 py-1 border text-sm font-medium"
        nextClassName="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-500 hover:bg-gray-50 rounded-r relative inline-flex items-center px-2 py-1 border text-sm font-medium"
        pageClassName="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-1 border text-sm font-medium"
        activeClassName="z-10 bg-green-50 dark:bg-green border-green dark:border-green dark:text-white"
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default NotificationsList;
