import React from "react"


const DeviceStatTile = ({ title, icon = null, className = '', value }) => {
  return (
    <div className={`flex flex-col flex-grow w-full p-4 rounded bg-white dark:bg-gray-800 shadow-sm transition-shadow ${className}`}>
      <div className="flex flex-row flex-grow items-center">
        { icon }
        <span className="text-sm text-gray-500 font-semibold">
          { title }
        </span>
      </div>
      <span className="text-xl font-bold text-gray-600 dark:text-gray-200 mt-1">
        { value }
      </span>
    </div>
  )
}

export default DeviceStatTile