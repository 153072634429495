import Loader from 'react-loaders'
import { ChevronRightIcon, PencilAltIcon } from "@heroicons/react/solid";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";

import AdvancedSelectBox from "../components/formik/AdvancedSelectBox";
import AssignmentModal from '../components/modals/AssignmentModal';
import DeleteDeviceModal from "../components/modals/DeleteDeviceModal";
import EditDeviceLocationModal from "../components/modals/EditDeviceLocationModal";
import EditDeviceNameModal from "../components/modals/EditDeviceNameModal";
import SelectBox from "../components/formik/SelectBox";
import { setDevices, setFocus } from "../redux/map";
import {
    useAssignEmployeesToDeviceMutation,
    useEditDeviceBinTypeMutation,
    useFindAllEmployeesQuery,
    useGetDeviceQuery,
    useUnassignEmployeesFromDeviceMutation,
    useUpdateNotificationsMutation,
    useUpdateSettingsMutation
} from '../redux/services/api'

const DeviceEmployeesModal = ({ open, onClose, device, employees, loading }) => {
    const { t } = useTranslation();
    const clearHandle = useRef(null);

    const [
        assignEmployeesToDevice,
        {
            isLoading: assignEmployeesToDeviceLoading,
            isSuccess: assignEmployeesToDeviceSuccess,
            isError: assignEmployeesToDeviceError,
            reset: assignEmployeesToDeviceReset
        }
    ] = useAssignEmployeesToDeviceMutation();

    const [
        unassignEmployeesFromDevice,
        {
            isLoading: unassignEmployeesFromDeviceLoading,
            isSuccess: unassignEmployeesFromDeviceSuccess,
            isError: unassignEmployeesFromDeviceError,
            reset: unassignEmployeesFromDeviceReset
        }
    ] = useUnassignEmployeesFromDeviceMutation();

    useEffect(() => {
        if (assignEmployeesToDeviceSuccess || unassignEmployeesFromDeviceSuccess) {
            toast.success(assignEmployeesToDeviceSuccess ? t('modals.devices_employees.toast_assign_success') : t('modals.devices_employees.toast_unassign_success'));
            assignEmployeesToDeviceReset();
            unassignEmployeesFromDeviceReset();
            clearHandle.current();
        }
    }, [assignEmployeesToDeviceSuccess, unassignEmployeesFromDeviceSuccess]);

    useEffect(() => {
        if (assignEmployeesToDeviceError || unassignEmployeesFromDeviceError) {
            toast.error(assignEmployeesToDeviceError ? t('modals.devices_employees.toast_assign_error') : t('modals.devices_employees.toast_unassign_error'));
            assignEmployeesToDeviceReset();
            unassignEmployeesFromDeviceReset();
            clearHandle.current();
        }
    }, [assignEmployeesToDeviceError, unassignEmployeesFromDeviceError]);

    return (
        <AssignmentModal
            open={open}
            loading={assignEmployeesToDeviceLoading || unassignEmployeesFromDeviceLoading || loading}
            onClose={onClose}
            onAssign={(employees) => assignEmployeesToDevice({ id: device?.id, employees })}
            onUnassign={(employees) => unassignEmployeesFromDevice({ id: device?.id, employees })}
            clear={clearHandle}
            data={employees}
            dataType={'employee'}
            entityID={device?.id}
            entityType={'device'}
            entityAttribute={'devices'}
            title={t('modals.device_employees.title')}
            assignButtonLabel={t('modals.device_employees.assign_button_label')}
            unassignButtonLabel={t('modals.device_employees.unassign_button_label')}
        />
    )
}

const DeviceSettingsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceID } = useParams()

    const {
        data: device,
        isLoading: loadingDevice,
        isSuccess: isSuccessDevice,
        isFetching: fetchingDevice,
    } = useGetDeviceQuery(deviceID);

    const {
        data: employees,
        isLoading: loadingEmployees,
        isFetching: fetchingEmployees,
    } = useFindAllEmployeesQuery();

    const [openDeviceNameModal, setOpenDeviceNameModal] = useState(false)
    const [openDeviceLocationModal, setOpenDeviceLocationModal] = useState(false)
    const [updatingCompacting, setUpdatingCompacting] = useState(false)
    const [updatingReporting, setUpdatingReporting] = useState(false)
    const [updatingNotificationLevel, setUpdatingNotificationLevel] = useState(false)
    const [updatingTemperature, setUpdatingTemperature] = useState(false)
    const [updatingWeight, setUpdatingWeight] = useState(false)
    const [openDeviceEmployeesModal, setOpenDeviceEmployeesModal] = useState(false)
    const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false)

    const BinTypeOptions = useMemo(() => {
        return [
            {
                label: t('components.device_tile.bin_types.general_waste'),
                value: 'general_waste'
            },
            {
                label: t('components.device_tile.bin_types.plastic'),
                value: 'plastic'
            },
            {
                label: t('components.device_tile.bin_types.glass'),
                value: 'glass'
            },
            {
                label: t('components.device_tile.bin_types.metal'),
                value: 'metal'
            },
            {
                label: t('components.device_tile.bin_types.glass_and_metal'),
                value: 'glass_and_metal'
            },
            {
                label: t('components.device_tile.bin_types.paper'),
                value: 'paper'
            },
            {
                label: t('components.device_tile.bin_types.shredded_paper'),
                value: 'shredded_paper'
            },
            {
                label: t('components.device_tile.bin_types.container_deposit'),
                value: 'container_deposit'
            },
            {
                label: t('components.device_tile.bin_types.food'),
                value: 'food'
            },
            {
                label: t('components.device_tile.bin_types.carton'),
                value: 'carton'
            },
            {
                label: t('components.device_tile.bin_types.cardboard'),
                value: 'cardboard'
            },
            {
                label: t('components.device_tile.bin_types.battery'),
                value: 'battery'
            },
            {
                label: t('components.device_tile.bin_types.electrical'),
                value: 'electrical'
            },
            {
                label: t('components.device_tile.bin_types.other'),
                value: 'other'
            }
        ];
    }, [t]);

    const compactingLevelOptions = [50, 75, 100];
    const pollingIntervalOptions = [30, 60, 90, 120];

    // Notification Level Select Methods
 	const NotificationLevelOptions = useMemo(() => {
        const data = device;
        return [
            {
                value: 50,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 50):true
            },
            {
                value: 55,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 55):true
            },
            {
                value: 60,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 60):true
            },
            {
                value: 65,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 65):true
            },
            {
                value: 70,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 70):true
            },
            {
                value: 75,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 75):true
            },
            {
                value: 80,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 80):true
            },
            {
                value: 85,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 85):true
            },
            {
                value: 90,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 90):true
            },
            {
                value: 95,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 95):true
            },
            {
                value: 100,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 100):true
            },
            {
                value: 105,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 105):true
            },
            {
                value: 110,
                disabled: (data?.settings?.fill?.threshold)?(data.settings.fill.threshold > 110):true
            }
        ]
    }, [device]);

    const currentBinType = useMemo(() => {
        if (device?.bin_type) {
            for (let option of BinTypeOptions) {
                if (option.value === device.bin_type) {
                    return option.value;
                }
            }
        }
        return "general_waste";
    }, [device, BinTypeOptions]);

    const temperatureThresholdOptions = [0, 40, 60, 80, 100];
    const weightThresholdOptions = [0, 10, 15, 20, 25];

    const [editBinType, {
        isLoading: loadingEditBinType,
    }] = useEditDeviceBinTypeMutation();

    const [updateSettings, {
        isLoading: loadingUpdateSettings,
        reset: resetUpdatingSettings
    }] = useUpdateSettingsMutation();

    const [updateNotifications, {
        isLoading: loadingUpdateNotifications,
        reset: resetUpdatingNotifications
    }] = useUpdateNotificationsMutation();

    const handleOpenDeviceNameModal = useCallback(() => {
        setOpenDeviceNameModal(true)
    }, [setOpenDeviceNameModal])

    const handleCloseDeviceModal = useCallback(() => {
        setOpenDeviceNameModal(false)
    }, [setOpenDeviceNameModal])

    const handleOpenDeviceLocationModal = useCallback(() => {
        setOpenDeviceLocationModal(true)
    }, [setOpenDeviceLocationModal])

    const handleCloseDeviceLocationModal = useCallback(() => {
        setOpenDeviceLocationModal(false)
    }, [setOpenDeviceLocationModal])

    const handleOpenDeviceEmployeesModal = useCallback(() => {
        setOpenDeviceEmployeesModal(true)
    }, [setOpenDeviceEmployeesModal])

    const handleCloseDeviceEmployeesModal = useCallback(() => {
        setOpenDeviceEmployeesModal(false)
    }, [setOpenDeviceEmployeesModal])

    const handleOpenDeleteDeviceModal = useCallback(() => {
        setOpenDeleteDeviceModal(true)
    }, [setOpenDeleteDeviceModal])

    const handleEditBinType = useCallback(async (binType) => {
        try {
            await editBinType({
                id: device.id,
                type: binType
            })
        } catch (err) {
            toast.error(err.message);
        }
    }, [editBinType, device])

    const handleCompactingLevelChange = useCallback(async (value) => {
        if (!device) return;
        setUpdatingCompacting(true);
        try {
            await updateSettings({
                id: device.id,
                fill: {
                    threshold: value
                },
            });
        } catch (err) {
            toast.error(err.message);
        }
        setUpdatingCompacting(false);
    }, [updateSettings, device])

    const handleReportingChange = useCallback(async (value) => {
        if (!device) return;
        setUpdatingReporting(true);
        try {
            await updateSettings({
                id: device.id,
                polling: {
                    interval: value
                },
            });
        } catch (err) {
            toast.error(err.message);
        }
        setUpdatingReporting(false);
    }, [updateSettings, device])

    const handleNotificationLevelChange = useCallback(async (value) => {
        if (!device) return;
        setUpdatingNotificationLevel(true);
        try {
            await updateNotifications({
                id: device.id,
                fill: {
                    threshold: value
                }
            });
        } catch (err) {
            toast.error(err.message);
        }
        setUpdatingNotificationLevel(false);
    }, [updateNotifications, device])

    const handleTemperatureChange = useCallback(async (value) => {
        if (!device) return;
        setUpdatingTemperature(true);
        try {
            await updateNotifications({
                id: device.id,
                temperature: {
                    enabled: value !== 0,
                    threshold: (value !== 0)?value:device.notifications.temperature.threshold,
                },
            });
        } catch (err) {
            toast.error(err.message);
        }
        setUpdatingTemperature(false);
    }, [updateNotifications, device])

    const handleWeightChange = useCallback(async (value) => {
        if (!device) return;
        setUpdatingWeight(true);
        try {
            await updateNotifications({
                id: device.id,
                weight: {
                    enabled: value !== 0,
                    threshold: (value !== 0)?value:device.notifications.weight.threshold,
                },
            });
        } catch (err) {
            toast.error(err.message);
        }
        setUpdatingWeight(false);
    }, [updateNotifications, device])

	const wifiSignalStrength = useMemo(() => {
        if (!device) return '';

		if (device.statistics.wifi.rssi > -50) {
			return t('pages.device_settings.network.signal_strengths.excellent');
		} else if (device.statistics.wifi.rssi > -60 && device.statistics.wifi.rssi < -50) {
			return t('pages.device_settings.network.signal_strengths.good');
		} else if (device.statistics.wifi.rssi > -70 && device.statistics.wifi.rssi < -60) {
			return t('pages.device_settings.network.signal_strengths.fair');
		} else {
			return t('pages.device_settings.network.signal_strengths.weak');
		}
    }, [device]);

    const lteSignalStrength = useMemo(() => {
        if (!device || !device.statistics.lte.available) return '';

        if (device.statistics.lte.rssi > -65) {
			return t('pages.device_settings.network.signal_strengths.excellent');
		} else if (device.statistics.lte.rssi > -75 && device.statistics.lte.rssi < -65) {
			return t('pages.device_settings.network.signal_strengths.good');
		} else if (device.statistics.lte.rssi > -85 && device.statistics.lte.rssi < -75) {
			return t('pages.device_settings.network.signal_strengths.fair');
		} else {
			return t('pages.device_settings.network.signal_strengths.weak');
		}
    }, [device]);

    const moseModel = useMemo(() => {
        if (!device) return '';

        if (device.product_model === 'aursund_75l') {
            return 'Aursund 75L';
        } else if (device.product_model === 'aursund_100l') {
            return 'Aursund 100L';
        } else if (device.product_model === 'konsis_75l') {
            return 'Konsis 75L';
        } else if (device.product_model === 'konsis_100l') {
            return 'Konsis 100L';
        } else if (device.product_model === 'nobel_75l') {
            return 'Nobel 75L';
        } else if (device.product_model === 'nobel_100l') {
            return 'Nobel 100L';
        } else {
            return 'MOSE';
        }
    }, [device]);

    // Set Map Focus on Init
    useEffect(() => {
        if (!isSuccessDevice || !device) return;
        dispatch(setDevices([device.id]));
        dispatch(setFocus([device.id]));
    }, [device, isSuccessDevice]);

    return (
        <div className="flex flex-col grow mx-4 mb-14">
            <EditDeviceNameModal open={openDeviceNameModal} onClose={handleCloseDeviceModal} value={device?.friendly_name} deviceID={device?.id} />
            <EditDeviceLocationModal open={openDeviceLocationModal} onClose={handleCloseDeviceLocationModal} value={device?.location} deviceID={device?.id} />
            <DeviceEmployeesModal open={openDeviceEmployeesModal} onClose={handleCloseDeviceEmployeesModal} device={device} employees={employees} loading={loadingDevice || fetchingDevice || loadingEmployees || fetchingEmployees} />
            <DeleteDeviceModal open={openDeleteDeviceModal} onClose={() => setOpenDeleteDeviceModal(false)} deviceID={device?.id} />
            {
                !loadingDevice &&
                <div className='flex flex-col grow-0 mt-2'>
                    <h6 className="text-sm text-gray-500 font-semibold">
                        { device?.friendly_name}
                    </h6>
                    <h2 className="text-3xl font-semibold mt-1 text-gray-700 dark:text-gray-200">
                        { t('pages.device_settings.title') }
                    </h2>
                </div>
            }
            {
                !loadingDevice &&
                <div className="flex flex-col gap-3 mt-4">
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.device_settings.general.title')}
                        </span>
                    </div>
                    <button onClick={handleOpenDeviceNameModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                            {t('pages.device_settings.general.settings.name')}
                        </span>
                        <div className="flex flex-row justify-center">
                            <span>
                                { device?.friendly_name }
                            </span>
                            <PencilAltIcon className="h-5 w-5 text-gray-600 ml-2" />
                        </div>
                    </button>
                    <button onClick={handleOpenDeviceLocationModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                        {t('pages.device_settings.general.settings.location')}
                        </span>
                        <div className="flex flex-row justify-center">
                            <span>
                                { device?.location }
                            </span>
                            <PencilAltIcon className="h-5 w-5 text-gray-600 ml-2" />
                        </div>
                    </button>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.general.settings.bin_type')}
                        </span>
                        <div className="w-[200px]">
                            <AdvancedSelectBox options={BinTypeOptions} value={currentBinType} symbol='' onChange={handleEditBinType} disabled={loadingEditBinType} loading={loadingEditBinType} />
                        </div>
                    </div>
                    {
                        device?.product_category === 'mose' &&
                        <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                            <span className="">
                                {t('pages.device_settings.general.settings.compacting_level')}
                            </span>
                            <div className="w-28">
                                <SelectBox options={compactingLevelOptions} value={device.settings.fill.threshold} symbol='%' onChange={handleCompactingLevelChange} disabled={loadingUpdateSettings} loading={updatingCompacting} />
                            </div>
                        </div>
                    }
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.general.settings.reporting_level')}
                        </span>
                        <div className="w-28">
                            <SelectBox options={pollingIntervalOptions} symbol=' min.' value={device.settings.polling.interval} onChange={handleReportingChange} disabled={loadingUpdateSettings} loading={updatingReporting} />
                        </div>
                    </div>
                    {/* Notifications */}
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.device_settings.notifications.title')}
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.notifications.settings.notification_level')}
                        </span>
                        <div className="w-28">
                            <AdvancedSelectBox options={NotificationLevelOptions} value={(device.notifications?.fill?.threshold)?device.notifications.fill.threshold:device.settings.fill.threshold} symbol='%' onChange={handleNotificationLevelChange} disabled={loadingUpdateNotifications} loading={updatingNotificationLevel} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.notifications.settings.temperature_threshold')}
                        </span>
                        <div className="w-28">
                            <SelectBox options={temperatureThresholdOptions} symbol=' °C' value={(device.notifications.temperature.enabled)?device.notifications.temperature.threshold:0} onChange={handleTemperatureChange} disabled={loadingUpdateNotifications} loading={updatingTemperature} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.notifications.settings.weight_threshold')}
                        </span>
                        <div className="w-28">
                            <SelectBox options={weightThresholdOptions} symbol='kg' value={(device.notifications.weight.enabled)?device.notifications.weight.threshold:0} onChange={handleWeightChange} disabled={loadingUpdateNotifications} loading={updatingWeight} />
                        </div>
                    </div>
                    {/* Employees */}
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.device_settings.employees.title')}
                        </span>
                    </div>
                    <button onClick={handleOpenDeviceEmployeesModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer">
                        <span className="">
                            {t('pages.device_settings.employees.settings.employees')}
                        </span>
                        <div className="flex flex-row items-center">
                            <span>
                                { device?.employees.length }
                            </span>
                            <ChevronRightIcon className="h-6 w-6 text-gray-600 ml-2" />
                        </div>
                    </button>
                    {/* Network */}
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.device_settings.network.title')}
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.network.settings.wifi_name')}
                        </span>
                        <span>
                            { device.statistics.wifi.ssid }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.network.settings.wifi_strength')}
                        </span>
                        <span>
                            { device.statistics.wifi.rssi } dBm ( { wifiSignalStrength } )
                        </span>
                    </div>
                    {
                        device.statistics.lte.available ?
                            <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                                <span className="">
                                    {t('pages.device_settings.network.settings.lte_strength')}
                                </span>
                                <span>
                                    { device.statistics.lte.rssi } dBm ( { lteSignalStrength } )
                                </span>
                            </div>
                        :
                            null
                    }
                    {/* Device Info */}
                    <div className="flex flex-row mt-3 mb-1">
                        <span className="uppercase text-xs font-semibold text-slate-500">
                            {t('pages.device_settings.device_info.title')}
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.model')}
                        </span>
                        <span>
                            { moseModel }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.serial_number')}
                        </span>
                        <span>
                            { device.serial_number }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.mac_address')}
                        </span>
                        <span>
                            { device.mac_address }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.firmware_version')}
                        </span>
                        <span>
                            { device.version.firmware }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.hardware_version')}
                        </span>
                        <span>
                            { device.version.hardware }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-slate-800 text-gray-500 dark:text-gray-400 w-full p-4 rounded">
                        <span className="">
                            {t('pages.device_settings.device_info.settings.battery_capacity')}
                        </span>
                        <span>
                            { device.statistics.battery.percentage.toFixed(2) }% ( { device.statistics.battery.voltage.toFixed(2) }V )
                        </span>
                    </div>
                    <button onClick={handleOpenDeleteDeviceModal} className="flex flex-row justify-between items-center bg-gray-200 hover:bg-gray-300 dark:bg-slate-800 hover:dark:bg-slate-700 text-gray-500 dark:text-gray-400 w-full p-4 rounded cursor-pointer mt-4">
                        <span className="text-red-500">
                            {t('pages.device_settings.delete_action')}
                        </span>
                    </button>
                </div>
            }
            {
                loadingDevice &&
                <div className="flex flex-col flex-grow items-center justify-center h-full">
                    <div className="flex flex-col justify-center items-center">
                        <Loader type="line-scale" active={true} />
                        <p className="mt-3 text-gray-600 text-center">
                            {t('loading')}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default DeviceSettingsPage;