import en from './en.json'
import no from './no.json'
import es from './es.json'
import fr from './fr.json'

const resources = {
    en,
    no,
    es,
    fr
};

export default resources;