import React from "react"
import { Outlet } from "react-router"
import { useSelector } from "react-redux";

import Breadcrumbs from "../components/Breadcrumbs";
import HistoryBar from "../components/HistoryBar"
import Map from "../components/Map"
import Navbar from "../components/Navbar"
import OverviewBar from "../components/OverviewBar"

const DashboardLayout = ({ children }) => {
  const open = useSelector(state => state.sidebar.open);

  return (
    <div className="flex flex-col flex-grow h-full items-stretch">
      <Navbar />
      <div className="flex flex-grow flex-col sm:flex-row dashboard-body">
        <div className={`flex flex-col flex-grow md:max-w-2/5 xl:max-w-7/20 shadow-xl z-0 bg-gray-100 dark:bg-gray-900 transition-colors ease-linear ${(!open)?'md:hidden':''}`}>
          <Breadcrumbs />
          <div className="flex flex-col flex-grow sm:overflow-y-auto soft-scrollbar scroll-smooth">
            <Outlet />
          </div>
        </div>
        <div className="relative flex flex-grow flex-col order-first md:order-last dark:bg-gray-900 sm:overflow-y-auto transition-colors ease-linear">
          <div className="sm:flex sm:flex-col md:absolute md:z-10 md:top-0 md:left-0 md:right-0 md:m-3 flex flex-col ">
            <div className="flex flex-row flex-grow bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 md:shadow-lg rounded md:mb-2">
              <HistoryBar />
            </div>
            <div className="flex flex-row flex-grow bg-white dark:bg-gray-800 shadow-lg rounded overflow-x-auto flex-nowrap">
              <OverviewBar />
            </div>
          </div>
          <div className='flex flex-col flex-grow'>
            <Map />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout