/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useMemo, useCallback, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SelectBox({ options, value, symbol, disabled = false, loading = false, onChange }) {
    const values = useMemo(() => {
        return options.map((value, index) => {
            return {
                id: index,
                value: value,
                label: (value === 0)?'OFF':`${value}${symbol}`,
            }
        })
    }, [options, symbol]);

    const selectedValue = useMemo(() => {
        return values.find((d) => d.value === value) || { id: -1, value: '', label: '' };
    }, [values, value]);

    const handleOnChange = useCallback((v) => {
        onChange(v.value);
    }, []);

    return (
        <Listbox value={selectedValue} onChange={handleOnChange}>
        {({ open }) => (
            <>
            <div className={`relative ${disabled?'pointer-events-none':''}`}>
                <Listbox.Button className="relative w-full bg-white dark:bg-slate-900 border border-gray-300 dark:border-slate-900 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none sm:text-sm">
                    <span className="flex items-center">
                        <span className="block truncate">{selectedValue.label}</span>
                    </span>
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        {
                            (!loading) ?
                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            :
                                <svg className="animate-spin h-4 w-4 text-green" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                        }
                    </span>
                </Listbox.Button>

                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {Array.isArray(values) && values.map((v) => (
                        <Listbox.Option
                            key={v.id}
                            className={({ active }) =>
                            classNames(
                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pr-9'
                            )
                            }
                            value={v}
                        >
                            {({ selected, active }) => (
                                <>
                                    <div className="flex items-center">
                                    <span
                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                    >
                                        {v.label}
                                    </span>
                                    </div>

                                    {selected ? (
                                    <span
                                        className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
                </Transition>
            </div>
            </>
        )}
        </Listbox>
    )
}