import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
	name: "search",
	initialState: {
        open: false,
	},
	reducers: {
        setSearchOpen(state, action) {
            state.open = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setSearchOpen } = searchSlice.actions;

export default searchSlice.reducer;