import React from "react";
import { PlusSmIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { CubeTransparentIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Avatar from 'react-avatar';
import Loader from 'react-loaders'
import { useTranslation } from "react-i18next";

import { useFindAllEmployeesQuery } from "../redux/services/api";

import AddEmployeeModal from "../components/modals/AddEmployeeModal";

const EmployeeTile = ({ employee }) => {
  return (
    <Link to={`/employee/${employee.id}`} className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:text-gray-700 hover:dark:text-gray-300 p-3 rounded">
      <div className="flex flex-row items-center">
        <Avatar maxInitials={2} name={employee.name} round={true} size={32} />
        <h3 className="font-semibold ml-3">
          {employee.name}
        </h3>
      </div>
      <ChevronRightIcon className="w-6 h-6" />
    </Link>
  )
}

const EmployeesPage = () => {
  const { t } = useTranslation();
  const [showAddEmployeeModal, setShowAddEmployeeModal] = React.useState(false);
  const { data, isLoading, isFetching, isSuccess } = useFindAllEmployeesQuery();

  return (
    <React.Fragment>
      <AddEmployeeModal open={showAddEmployeeModal} onClose={() => setShowAddEmployeeModal(false)} />
      <div className="flex flex-col flex-grow px-4 my-3">
        {/* Create Filled Device heading with TrendingUpIcon */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
              {t('pages.employees.title')}
            </h3>
            {/* Display employees count in badge */}
            {
              isSuccess &&
              <div className="ml-3 mt-0.5 text-sm bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-3 rounded">
                {data && data.length}
              </div>
            }
          </div>
          <div>
            <button
              onClick={() => setShowAddEmployeeModal(true)}
              className="flex flex-row items-center justify-center py-1 px-3 text-xs font-semibold rounded text-gray-400 bg-gray-200 dark:bg-gray-800 focus:dark:bg-gray-700 hover:dark:bg-gray-700 dark:text-gray-400 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:shadow-outline transition-colors duration-150"
            >
              <PlusSmIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
        {/* Show employees if isSuccess and length > 0 otherwise show empty icon */}
        {isSuccess && !isLoading && !isFetching  && 
          <>
            {
              (data.length > 0) ?
                <div className="grid grid-flow-row grid-col-1 gap-4 my-5">
                  {data.map(employee => (
                    <EmployeeTile key={employee.id} employee={employee} />
                  ))}
                </div>
              :
                <div className="flex flex-col flex-grow items-center justify-center">
                  <div className="flex flex-col justify-center items-center md:-mt-8">
                    <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
                    <p className="mt-3 text-gray-600 text-center">
                      {t('pages.employees.empty')}
                    </p>
                  </div>
                </div>
            }
          </>
        }
        {
          // Show loading indicator while data is loading
          (isLoading || isFetching) &&
            <div className="flex flex-col flex-grow items-center justify-center">
              <div className="flex flex-col justify-center items-center md:-mt-8">
                <Loader type="line-scale" active={true} />
                <p className="mt-3 text-gray-600 text-center">
                  {t('loading')}
                </p>
              </div>
            </div>
        }
      </div>
    </React.Fragment>
  );
};

export default EmployeesPage;
