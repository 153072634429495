import { createSlice } from "@reduxjs/toolkit";

export const overviewBarSlice = createSlice({
	name: "overviewbar",
	initialState: {
    loading: true,
    show: false,
    compressions: 0,
    compression_factor: 0,
    average_fill_level: 0,
    times_emptied: 0
	},
	reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setCompressions: (state, action) => {
      state.compressions = action.payload;
    },
    setCompressionFactor: (state, action) => {
      state.compression_factor = action.payload;
    },
    setAverageFillLevel: (state, action) => {
      state.average_fill_level = action.payload;
    },
    setTimesEmptied: (state, action) => {
      state.times_emptied = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setShow, setCompressions, setCompressionFactor, setAverageFillLevel, setTimesEmptied } = overviewBarSlice.actions;

export default overviewBarSlice.reducer;