import React, { useEffect } from "react";
import { CubeTransparentIcon, TrendingUpIcon } from "@heroicons/react/outline";
import { useFindAllDevicesQuery } from "../../redux/services/api";

import DeviceList from "../../components/DeviceList";

import { setDevices, setFocus } from "../../redux/map";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


const FilledDevicesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Get all devices
  const {
    data: devices,
    isSuccess: devicesSuccess
  } = useFindAllDevicesQuery();

  // Get Filled devices
  const filledDevices = (devicesSuccess)?devices.filter(device => device.statistics.device.fill_level > device.settings.fill.threshold):[];

  // Set Filled devices on map
  useEffect(() => {
    if (!filledDevices) return;
    const serializedDevices = filledDevices.map((d) => d.id);
    dispatch(setDevices(serializedDevices));
    dispatch(setFocus(serializedDevices));
  }, [filledDevices]);

  return (
    <div className="flex flex-col px-4 my-3">
      {/* Create Filled Device heading with TrendingUpIcon */}
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {t('pages.stats.filled.title')}
        </h1>
        <div className="flex flex-row items-center">
          <TrendingUpIcon className="w-7 h-7 text-yellow-500" />
          <span className="text-lg text-gray-500 font-semibold ml-2">
            {filledDevices.length}
          </span>
        </div>
      </div>
      <p className="mt-2 text-gray-400 dark:text-gray-500">
        {t('pages.stats.filled.description')}
      </p>
      {/* Show Filled Devices List */}
      <div className="flex flex-col my-6">
        <DeviceList absolute={true} items={filledDevices} itemsPerPage={15} />
      </div>
      {/* Show empty icon if there are no devices */}
      {filledDevices.length === 0 && (
        <div className="flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col justify-center items-center md:-mt-8">
            <CubeTransparentIcon className="w-10 h-10 text-gray-500" />
            <p className="mt-3 text-gray-600 text-center">
              {t('pages.stats.filled.empty')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilledDevicesPage;
