import { createSlice } from "@reduxjs/toolkit";

export const slidebarSlice = createSlice({
	name: "sidebar",
	initialState: {
        open: true,
	},
	reducers: {
        setOpen(state, action) {
            state.open = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setOpen } = slidebarSlice.actions;

export default slidebarSlice.reducer;