import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';

import Router from './Router';
import SplashScreen from './components/SplashScreen';
import store from './redux/store'
import { BASE_URL } from './api/config';
import { DragLayer } from './components/DragLayer';
import { loadTheme } from './redux/theme';
import { login, logout } from './redux/auth';
import { useLazyGetProfileQuery } from './redux/services/api';

const wait = () => new Promise((resolve) => setTimeout(resolve, 1600));

const App = () => {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();
  const [loadProfile, {
    isSuccess: isSuccessProfile
  }] = useLazyGetProfileQuery();

  useEffect(() => {
    if (loggedIn && !isSuccessProfile) {
      loadProfile();
    }
  }, [loggedIn, isSuccessProfile, loadProfile]);

  return (
    <SplashScreen
      show={(loggedIn)?!isSuccessProfile:false}
      startAsync={() => new Promise((resolve, reject) => {
        (async () => {
          try {
            // Load theme
            await dispatch(loadTheme());

            const access_token = localStorage.getItem("access_token");
            const access_token_expiry = localStorage.getItem("access_token_expiry");
            const refresh_token = localStorage.getItem("refresh_token");
            const refresh_token_expiry = localStorage.getItem("refresh_token_expiry");
            const algolia_key = localStorage.getItem("algolia_key") || "";
            
            if (access_token) {
              if (access_token_expiry && new Date(access_token_expiry).getTime() > new Date().getTime()) {
                // All good, access token is valid for now
                dispatch(login({
                  access_token,
                  access_token_expiry,
                  refresh_token,
                  refresh_token_expiry,
                  algolia_key
                }));
                loadProfile()
                return resolve();
              } else {
                // Try refreshing tokens
                if (refresh_token && refresh_token_expiry && new Date(refresh_token_expiry).getTime() > new Date().getTime()) {
                  const response = await fetch(`${BASE_URL}/auth/organization/refresh`, {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      token: refresh_token
                    })
                  });
                  if (response.status === 200) {
                    const payload = await response.json();
                    dispatch(login({
                      ...payload
                    }))
                    loadProfile()
                    return resolve();
                  }
                } else {
                  // Refresh token expired as well, clear tokens
                  dispatch(logout());
                }
              }
            }
            await wait();
            return resolve();
          } catch (err) {
            console.error(err)
            return reject(err)
          }
        })();
      })}
    >
      <Router/>
    </SplashScreen>
  )
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <DragLayer />
        <App />
      </DndProvider>
      <ToastContainer
        position="bottom-right"
        toastClassName="bg-gray-800 text-white border border-gray-700 rounded-lg px-4"
        theme="dark"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>
  );
}

export default AppWrapper;
