import { CheckCircleIcon } from "@heroicons/react/solid"
import { useTranslation } from 'react-i18next';

const LanguageTile = ({ name, active, onClick }) => {
    return (
        <div className="flex flex-col rounded-lg bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 dark:bg-gray-800 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 cursor-pointer transition-colors" onClick={onClick}>
            <div className="flex flex-row items-center py-4 px-4 justify-between">
                <div className="font-semibold">
                    { name }
                </div>
                {
                    active ?
                        <CheckCircleIcon className="w-6 h-6 text-green" />
                    :
                        null
                }
            </div>
        </div>
    )
}

const LanguagePage = () => {
    const { t, i18n } = useTranslation();

    // Set language
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        // store language in localStorage
        localStorage.setItem('language', lang);
    };

    return (
        <div className="flex flex-col flex-grow justify-start px-4 my-2 gap-3">
            <div className="flex flex-col mb-3">
                <div className="flex flex-row">  
                    <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 mr-4">
                        { t('pages.settings.language.title') } ( <span className="uppercase">{ i18n.language }</span> )
                    </h1>
                </div>
            </div>
            <LanguageTile name="English ( EN )" active={i18n.language === 'en'} onClick={() => handleLanguageChange('en')} />
            <LanguageTile name="Norsk ( NO )" active={i18n.language === 'no'} onClick={() => handleLanguageChange('no')} />
            <LanguageTile name="Español ( ES )" active={i18n.language === 'es'} onClick={() => handleLanguageChange('es')} />
            <LanguageTile name="Français ( FR )" active={i18n.language === 'fr'} onClick={() => handleLanguageChange('fr')} />
        </div>
    )
}

export default LanguagePage