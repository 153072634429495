import React, { useMemo } from "react";

const BinSVG = ({ product_category = 'mose', fill, notification_threshold, threshold = 100, ...props }) => {
	const startYAxis = 425;
	const color = useMemo(() => {
		if (product_category === 'mose') {
			if (fill >= threshold) {
				if (notification_threshold) {
					if (fill > notification_threshold) {
						return "text-red-500 dark:text-red-500"
					} else {
						return "text-yellow-500 dark:text-yellow-500"
					}
				} else {
					return "text-orange-500 dark:text-orange-500"
				}
			}
		} else if (product_category === 'tek') {
			if (notification_threshold) {
				if (fill > notification_threshold) {
					return "text-red-500 dark:text-red-500"
				}
			}
		}

		return "text-green-400 dark:text-green-500"
	}, [product_category, fill, notification_threshold, threshold]);

	if (fill > 100) { 
		fill = 100;
	}

	if (fill < 0) {
		fill = 0;
	}

	return (
		<div {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266 430" {...props}>
				<rect
					x={7.948}
					y={10}
					width={249.838}
					height={412.298}
					rx={32}
					ry={32}
					strokeWidth={16}
					strokeLinecap="round"
					strokeLinejoin="round"
          			className="fill-current text-gray-300 dark:text-gray-600"
				/>
				<rect
					x={7.948}
					y={startYAxis - (startYAxis * fill / 100)}
					width={249.838}
					height={Math.abs((startYAxis * fill / 100))}
					rx={32}
					ry={32}
					// Change className text to red if threshold is reached
					className={`fill-current ${ color }`}
				/>
				<circle cx={135} cy={158.36} r={76.505} className="fill-current text-white dark:text-gray-500" opacity={0.7} />            
			</svg>
		</div>
	);
};


export default BinSVG;