import React from "react";
import BaseLoader from "./BaseLoader";

const GroupTileLoader = (props) => {
  return (
    <div {...props}>
      <BaseLoader viewBox={"0 0 200 72"} style={{ maxWidth: 200, height: 72 }}>
        <rect x="0" y="0" rx="4" ry="4" width="200" height="72" />
      </BaseLoader>
    </div>
  )
}

export default GroupTileLoader