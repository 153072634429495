import React from "react";
import BaseLoader from "./BaseLoader";

const DeviceTileLoader = (props) => {
  return (
    <div {...props}>
      <BaseLoader viewBox={"0 0 435 82"} style={{ maxWidth: 435, height: 82 }}>
        <rect x="0" y="0" rx="4" ry="4" width="435" height="82" />
      </BaseLoader>
    </div>
  )
}

export default DeviceTileLoader