import useBreadcrumbs from 'use-react-router-breadcrumbs';
import React, { useEffect, useState } from "react"
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

import { useFindAllDevicesQuery, useFindAllEmployeesQuery, useFindAllGeoreferencingQuery, useFindAllGroupsQuery } from '../redux/services/api'

const DynamicEmployeeBreadcrumb = ({ match }) => {
  const [EmployeeName, setEmployeeName] = useState("");
  const {
    data: employees,
    isSuccess: employeesSuccess
  } = useFindAllEmployeesQuery();

  useEffect(() => {
    if (employeesSuccess) {
      for (let employee of employees) {
        if (employee.id === match.params.employeeID) {
          setEmployeeName(employee.name);
        }
      }
    }
  }, [match.params.employeeID, employees, employeesSuccess]);

  return (
    <span className="dark:text-gray-400">{ EmployeeName }</span>
  );
};

const DynamicGroupBreadcrumb = ({ match }) => {
  const [GroupName, setGroupName] = useState("");
  const {
    data: groups,
    isSuccess: groupsSuccess
  } = useFindAllGroupsQuery();

  useEffect(() => {
    if (groupsSuccess) {
      for (let group of groups) {
        if (group.id === match.params.groupID) {
          setGroupName(group.name);
        }
      }
    }
  }, [match.params.groupID, groups, groupsSuccess]);

  return (
    <span className="dark:text-gray-400">{ GroupName }</span>
  );
};

const DynamicDeviceBreadcrumb = ({ match }) => {
  const [DeviceName, setDeviceName] = useState("");
  const {
    data: devices,
    isSuccess: devicesSuccess
  } = useFindAllDevicesQuery();

  useEffect(() => {
    if (devicesSuccess) {
      for (let device of devices) {
        if (device.id === match.params.deviceID) {
          setDeviceName(device.friendly_name);
        }
      }
    }
  }, [match.params.deviceID, devices, devicesSuccess]);

  return (
    <span className="dark:text-gray-400">{ DeviceName }</span>
  );
};

const DynamicGeoreferenceBreadcrumb = ({ match }) => {
  const [GeoreferenceName, setGeoreferenceName] = useState("");
  const {
    data: georeferences,
    isSuccess: georeferencesSuccess
  } = useFindAllGeoreferencingQuery();

  useEffect(() => {
    if (georeferencesSuccess) {
      for (let georeference of georeferences) {
        if (georeference.id === match.params.georeferenceID) {
          setGeoreferenceName(georeference.name);
        }
      }
    }
  }, [match.params.georeferenceID, georeferences, georeferencesSuccess]);

  return (
    <span className="dark:text-gray-400">{ GeoreferenceName }</span>
  );
};



const Breadcrumbs = () => {
  const { t } = useTranslation();

  const routes = [
    { path: '/', breadcrumb: t('pages.homepage.breadcrumb') },
    { path: '/settings', breadcrumb: t('pages.settings.breadcrumb') },
    { path: '/settings/language', breadcrumb: t('pages.settings.language.title') },
    { path: '/settings/georeferencing', breadcrumb: t('pages.settings.georeferencing.breadcrumb') },
    { path: '/settings/georeferencing/:georeferenceID', breadcrumb: DynamicGeoreferenceBreadcrumb },
    { path: '/notifications', breadcrumb: t('pages.notifications.breadcrumb') },
    { path: '/stats/filled', breadcrumb: t('pages.stats.filled.breadcrumb') },
    { path: '/stats/low-battery', breadcrumb: t('pages.stats.lowbattery.breadcrumb') },
    { path: '/stats/exceptions', breadcrumb: t('pages.stats.exceptions.breadcrumb') },
    { path: '/group/:groupID', breadcrumb: DynamicGroupBreadcrumb },
    { path: '/group/:groupID/settings', breadcrumb: t('pages.group_settings.breadcrumb') },
    { path: '/group/:groupID/device/:deviceID', breadcrumb: DynamicDeviceBreadcrumb },
    { path: '/group/:groupID/device/:deviceID/settings', breadcrumb: t('pages.device_settings.breadcrumb') },
    { path: '/device/:deviceID', breadcrumb: DynamicDeviceBreadcrumb },
    { path: '/device/:deviceID/settings', breadcrumb: t('pages.device_settings.breadcrumb') },
    { path: '/employee', breadcrumb: t('pages.employees.breadcrumb') },
    { path: '/employee/:employeeID', breadcrumb: DynamicEmployeeBreadcrumb },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <div className="flex flex-row p-4 overflow-x-auto overflow-y-hidden text-gray-400 dark:text-gray-400">
      <div className="inline-flex flex-row flex-nowrap items-center">
        <div className="flex flex-row">
          <HomeIcon className="w-4 h-4 mr-2" />
        </div>
        {
          breadcrumbs.map(({
            match,
            breadcrumb
          }, index, arr) => (
            <div className="flex flex-row items-center tracking-wide" key={index}>
              {
                (index !== 0) ?
                  <ChevronRightIcon className="w-4 h-4 mx-2" />
                :
                  null
              }
              <Link to={match.pathname} className="font-semibold text-sm">
                {breadcrumb}
              </Link>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Breadcrumbs;