import React from "react"
import { PencilAltIcon } from "@heroicons/react/solid"
import { useGetEmployeeQuery, useGetProfileQuery } from '../redux/services/api'
import { useParams } from "react-router-dom"

import BaseLoader from "../components/loaders/BaseLoader"
import Avatar from 'react-avatar';
import EditEmployeeNameModal from "../components/modals/EditEmployeeNameModal"
import EditEmployeePasswordModal from "../components/modals/EditEmployeePasswordModal"
import DeleteEmployeeModal from "../components/modals/DeleteEmployeeModal"
import { useTranslation } from "react-i18next"

const EmployeePage = () => {
  const { t } = useTranslation();
  const [showEditEmployeeNameModal, setShowEditEmployeeNameModal] = React.useState(false);
  const [showEditEmployeeChangePasswordModal, setShowEditEmployeeChangePasswordModal] = React.useState(false);
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = React.useState(false);
  const URLParams = useParams();

  const {
    data: profile
  } = useGetProfileQuery();

  const {
    data: employee,
    isLoading: loadingEmployee,
    isSuccess: isSuccessEmployee,
    isError: isErrorEmployee
  } = useGetEmployeeQuery(URLParams.employeeID);

  return (
    <div className="flex flex-col flex-grow mx-10">
      <EditEmployeeNameModal open={showEditEmployeeNameModal} onClose={() => setShowEditEmployeeNameModal(false)} value={(employee)?employee.name:''} employeeID={employee?.id} />
      <EditEmployeePasswordModal open={showEditEmployeeChangePasswordModal} onClose={() => setShowEditEmployeeChangePasswordModal(false)} employeeID={employee?.id} />
      <DeleteEmployeeModal open={showDeleteEmployeeModal} onClose={() => setShowDeleteEmployeeModal(false)} employeeID={employee?.id} />
      {/* Show loader while loading employee */}
      { loadingEmployee && (
        <>
          <div className="flex flex-row justify-center mt-10">
            <BaseLoader viewBox={"0 0 64 64"} style={{ width: 128, height: 128 }}>
              {/* Create svg circle of 64px */}
              <circle
                cx="32"
                cy="32"
                r="32"
              />              
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-center my-8">
            <BaseLoader viewBox={"0 0 200 30"} style={{ width: 200, height: 30 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="30" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
          <div className="flex flex-row justify-between my-4">
            <BaseLoader viewBox={"0 0 200 24"} style={{ width: 200, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
            </BaseLoader>
            <BaseLoader viewBox={"0 0 100 24"} style={{ width: 100, height: 24 }}>
              <rect x="0" y="0" rx="4" ry="4" width="100" height="24" />
            </BaseLoader>
          </div>
        </>
      )}
      {/* Show employee is success */}
      { isSuccessEmployee && (
        <>
          <div className="flex flex-row justify-center mt-10">
            <Avatar maxInitials={2} name={employee.name} round={true} size={98} />
          </div>
          <div className="flex flex-row justify-center my-6">
            <span className="text-3xl font-semibold text-gray-700 dark:text-gray-300">
              {employee.name}
            </span>
          </div>
          <div className="grid grid-flow-row grid-cols-1 gap-4 my-4">
            <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 p-4 rounded">
              <div>
                {t('pages.employee.organization_id')}
              </div>
              <div>
                { (profile)?profile.friendly_id:'' }
              </div>
            </div>
            <button onClick={() => setShowEditEmployeeNameModal(true)} className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:text-gray-700 hover:dark:text-gray-300 p-4 rounded">
              <div>
                {t('pages.employee.name')}
              </div>
              <div className="flex flex-row items-center">
                <div className="mr-2">{ employee.name }</div>
                <PencilAltIcon className="w-5 h-5" />
              </div>
            </button>
            <div className="flex flex-row justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 p-4 rounded">
              <div>
                {t('pages.employee.username')}
              </div>
              <div>
                { employee.username }
              </div>
            </div>
            <button onClick={() => setShowEditEmployeeChangePasswordModal(true)} className="flex flex-row flex-grow justify-between items-center bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:text-gray-700 hover:dark:text-gray-300 p-4 rounded">
              <div>
                {t('pages.employee.change_password_action')}
              </div>
              <PencilAltIcon className="w-5 h-5" />
            </button>
            <button onClick={() => setShowDeleteEmployeeModal(true)} className="flex flex-row flex-grow justify-center items-center bg-red-500 hover:bg-red-600 dark:bg-red-700 hover:dark:bg-red-600 text-white p-3 rounded">
              <div>
                {t('pages.employee.delete_action')}
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default EmployeePage
